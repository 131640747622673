import {
  Box,
  Checkbox,
  Divider,
  Group,
  InputWrapper,
  MultiSelect,
  NumberInput,
  Paper,
  Select,
  SelectProps,
  Stack,
  Switch,
  TagsInput,
  TextInput,
  Textarea,
} from '@mantine/core';
import PixiForm from '@pixi/elements/Form';
import { ReactElement } from 'react';
import { types } from './helper';
import { DateInput } from '@mantine/dates';
import { format, isValid } from 'date-fns';

export default function PropertyValueForm({
  property,
  target,
  onConfirm,
  value,
}: {
  property: Pickit.CommunityPropertyInterface;
  target: ReactElement;
  onConfirm: (value: Pickit.PropertyValue) => Promise<void> | void;
  value?: Pickit.PropertyValue;
}) {
  return (
    <PixiForm<{
      value?: Pickit.PropertyValue;
    }>
      type="dropdown"
      key={JSON.stringify(value)}
      width={400}
      position="bottom-end"
      target={target}
      keepMounted
      onSubmit={async (data) => {
        await onConfirm(data.value as Pickit.PropertyValue);
      }}
      form={{
        value: {
          key: 'value',
          value: value || {},
          render: ({ data, setData }) => {
            let field = <></>;
            const view = types?.find((f) => f.value === property?.type);
            if (!view || !property?.name) {
              return <></>;
            }
            if (property?.type === 'dropdown') {
              if (property?.typeProps?.dropdown?.multiSelect) {
                field = (
                  <MultiSelect
                    label={property.name}
                    description={property.description}
                    data={property?.typeProps?.dropdown?.options || []}
                    autoFocus
                    value={
                      data?.value?.dropdown?.map((m) => {
                        const value =
                          property?.typeProps?.dropdown?.options?.find(
                            (f) => f.label === m,
                          )?.value;
                        return value;
                      }) as string[]
                    }
                    onChange={(val) => {
                      const labels = val.map(
                        (v) =>
                          property?.typeProps?.dropdown?.options?.find(
                            (o) => o.value === v,
                          )?.label,
                      ) as string[];
                      setData({
                        value: {
                          dropdown: labels,
                        },
                      });
                    }}
                  />
                );
              } else {
                field = (
                  <Select
                    label={property.name}
                    description={property.description}
                    data={property?.typeProps?.dropdown?.options || []}
                    value={
                      property?.typeProps?.dropdown?.options?.find(
                        (o) => o.label === data?.value?.dropdown?.[0],
                      )?.value as string
                    }
                    onChange={(val) => {
                      const label =
                        property?.typeProps?.dropdown?.options?.find(
                          (o) => o.value === val,
                        );
                      if (!label) {
                        return;
                      }
                      setData({
                        value: {
                          dropdown: [label.label],
                        },
                      });
                    }}
                  />
                );
              }
            }
            if (property?.type === 'boolean') {
              field = (
                <Switch
                  label={property.name}
                  description={property.description}
                  checked={data?.value?.boolean || false}
                  onChange={(event) => {
                    setData({
                      value: {
                        boolean: event.currentTarget.checked,
                      },
                    });
                  }}
                />
              );
            }
            if (property?.type === 'date') {
              field = (
                <DateInput
                  label={property.name}
                  description={property.description}
                  value={
                    data?.value?.date && isValid(data?.value?.date)
                      ? new Date(data?.value?.date)
                      : undefined
                  }
                  onChange={(date) => {
                    if (!date) {
                      return;
                    }
                    const selectedDate = new Date(date.setHours(0, 0, 0, 0));
                    const utcDate = new Date(
                      selectedDate.getTime() -
                        selectedDate.getTimezoneOffset() * 60000,
                    );

                    setData({
                      value: {
                        date: utcDate,
                      },
                    });
                  }}
                />
              );
            }
            if (property?.type === 'tags') {
              field = (
                <TagsInput
                  label={property.name}
                  description={property.description}
                  value={data?.value?.tags || []}
                  acceptValueOnBlur
                  onChange={(val) => {
                    setData({
                      value: {
                        tags: val,
                      },
                    });
                  }}
                />
              );
            }
            if (property?.type === 'number') {
              field = (
                <NumberInput
                  label={property.name}
                  description={property.description}
                  onChange={(val) => {
                    setData({
                      value: {
                        number: parseInt(val as string),
                      },
                    });
                  }}
                />
              );
            }
            if (property?.type === 'text') {
              field = (
                <TextInput
                  label={property.name}
                  description={property.description}
                  value={data.value?.text || ''}
                  onChange={(event) => {
                    setData({
                      value: {
                        text: event.currentTarget.value,
                      },
                    });
                  }}
                />
              );
            }
            return field;
          },
        },
      }}
    />
  );
}
