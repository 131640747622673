import {
  Button,
  Group,
  Input,
  InputWrapper,
  MantineColorsTuple,
  MantineProvider,
  MantineTheme,
  MenuProps,
  Popover,
  ScrollAreaAutosize,
  SegmentedControlProps,
  Select,
  Stack,
  Switch,
  Tabs,
  TagsInput,
  TextInput,
  Textarea,
  VariantColorsResolver,
  createTheme,
  defaultVariantColorsResolver,
  rem,
} from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@pixi/Theme.css';
import { useRouter } from 'hooks/useRouter';
import { ReactNode, useMemo, useState } from 'react';
import Values from 'values.js';
import { useColorScheme } from './AppController';
import System from './System';
import { rgbToHex } from './helpers/utils';
import { useAppUser } from './AppUserController';
import { useWindowEvent } from '@mantine/hooks';

export function createColorShades(color: string) {
  const Color = new Values(color);
  const colors = Color.all(10);
  return [
    rgbToHex(colors[1].rgb),
    rgbToHex(colors[3].rgb),
    rgbToHex(colors[4].rgb),
    rgbToHex(colors[5].rgb),
    rgbToHex(colors[6].rgb),
    rgbToHex(colors[7].rgb),
    rgbToHex(colors[9].rgb), // Primary
    rgbToHex(colors[10].rgb),
    rgbToHex(colors[11].rgb),
    rgbToHex(colors[12].rgb),
  ] as MantineColorsTuple;
}

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);

  // if (input.variant === 'danger') {
  //   return {
  //     background: 'var(--mantine-color-red-9)',
  //     hover: 'var(--mantine-color-red-8)',
  //     color: 'var(--mantine-color-white)',
  //     border: 'none',
  //   };
  // }

  return defaultResolvedColors;
};

export const ScaleDown = {
  in: { opacity: 1, transform: 'scale(1)' },
  out: { opacity: 0, transform: 'scale(2.4)' },
  transitionProperty: 'transform, opacity',
  common: { transformOrigin: 'center' },
};
export const TopLeftTransition = {
  in: { opacity: 1, transform: 'scale(1)' },
  out: { opacity: 0, transform: 'scale(.9)' },
  transitionProperty: 'transform, opacity',
  common: { transformOrigin: 'top left' },
};
export const TopCenterTransition = {
  in: { opacity: 1, transform: 'scale(1)' },
  out: { opacity: 0, transform: 'scale(.9)' },
  transitionProperty: 'transform, opacity',
  common: { transformOrigin: 'top center' },
};
export const TopRightTransition = {
  in: { opacity: 1, transform: 'scale(1)' },
  out: { opacity: 0, transform: 'scale(.9)' },
  transitionProperty: 'transform, opacity',
  common: { transformOrigin: 'top right' },
};
export const BottomLeftTransition = {
  in: { opacity: 1, transform: 'scale(1)' },
  out: { opacity: 0, transform: 'scale(.9)' },
  transitionProperty: 'transform, opacity',
  common: { transformOrigin: 'bottom left' },
};
export const BottomCenterTransition = {
  in: { opacity: 1, transform: 'scale(1)' },
  out: { opacity: 0, transform: 'scale(.9)' },
  transitionProperty: 'transform, opacity',
  common: { transformOrigin: 'bottom center' },
};
export const BottomRightTransition = {
  in: { opacity: 1, transform: 'scale(1)' },
  out: { opacity: 0, transform: 'scale(.9)' },
  transitionProperty: 'transform, opacity',
  common: { transformOrigin: 'bottom right' },
};

const theme = createTheme({
  variantColorResolver,
  colors: {
    pickit: createColorShades('#6716D8'),
    secondary: createColorShades('#F5C29F'),
    tertiary: createColorShades('#FF0091'),
    quaternary: createColorShades('#96DDD1'),
    'digital-forest': createColorShades('#8CFD9B'),
    pickit_green: createColorShades('#8CFD9B'),
    pickit_pink: createColorShades('#FF0091'),
    pickit_purple: createColorShades('#6716D8'),
    pickit_blue: createColorShades('#96DDD1'),
    pickit_yellow: createColorShades('#F5C29F'),
  },
  fontFamily: `system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
  fontSmoothing: false,
  fontSizes: {
    xs: rem(14), // rem(14),
    sm: rem(16),
    md: rem(18),
    lg: rem(24),
    xl: rem(30),
  },
  radius: {
    xs: rem(4),
    sm: rem(8),
    md: rem(8),
    lg: rem(12),
    xl: rem(100),
  },
  components: {
    Paper: {},
    Badge: {
      defaultProps: {
        fw: '500',
      },
    },
    Stack: Group.extend({
      defaultProps: {
        miw: 1,
      },
    }),
    Group: Group.extend({
      defaultProps: {
        miw: 1,
      },
    }),
    SegmentedControl: {
      defaultProps: {
        p: '8',
      },
      styles: (theme: MantineTheme, props: SegmentedControlProps) => ({
        root: {
          ...(props.variant === 'glass'
            ? {
                background: `rgba(0, 0, 0, 0.05)`,
              }
            : {}),
        },
        label: {
          display: 'flex',
          alignItems: 'center',
        },
      }),
    },
    Tabs: Tabs.extend({
      styles: (theme, props) => {
        if (props.variant === 'pills') {
          return {
            tab: {
              width: 'auto',
              fontSize: theme.fontSizes.xs,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 16,
              paddingRight: 16,
              fontWeight: 500,
            },
            list: {
              justifyContent: 'center',
              display: 'flex',
              gap: theme.spacing.xs,
              marginBottom: theme.spacing.lg,
            },
          };
        }
        return {};
      },
      vars: (_theme, props) => {
        if (props.variant === 'pills') {
          return {
            root: {
              '--tabs-color': 'var(--mantine-color-secondary-6)',
              '--tabs-text-color': 'var(--mantine-color-dark-6)',
              '--tabs-radius': 'var(--mantine-radius-xl)',
            },
          };
        }
        return {
          root: {},
        };
      },
    }),
    TagsInput: TagsInput.extend({
      defaultProps: {
        acceptValueOnBlur: true,
        styles: {
          input: {
            maxHeight: 150,
            overflow: 'auto',
          },
        },
      },
      vars: (_theme, props) => {
        if (props.variant === 'white') {
          return {
            root: {
              '--input-bg': `rgba(0, 0, 0, 0.05)`,
              '--mantine-color-placeholder': `var(--mantine-color-${props.color || 'dark'}-6)`,
              '--input-section-color': `var(--mantine-color-${props.color || 'dark'}-6)`,
            },
            input: {
              background: `var(--mantine-color-white)`,
              color: `var(--mantine-color-${props.color || 'dark'}-6)`,
            },
          };
        }
        if (props.variant === 'dark-glass') {
          return {
            root: {
              '--input-bg': `rgba(0, 0, 0, 0.2)`,
              '--mantine-color-placeholder': 'rgba(255, 255, 255, 1)',
              '--input-section-color': 'rgba(255, 255, 255, 0.6)',
            },
          };
        }
        if (props.variant === 'glass') {
          if (props.color === 'white' || _theme.other?.colorScheme === 'dark') {
            return {
              root: {
                '--input-bg': `rgba(255, 255, 255, 0.1)`,
                '--mantine-color-placeholder': 'rgba(255, 255, 255, 0.45)',
                '--input-section-color': 'rgba(255, 255, 255, 0.6)',
                '--mantine-color-text': '#FFFFFF',
              },
            };
          }
          return {
            root: {
              '--input-bg': `rgba(0, 0, 0, 0.05)`,
              '--mantine-color-placeholder': 'rgba(0, 0, 0, 0.6)',
              '--input-section-color': 'rgba(0, 0, 0, 0.6)',
            },
          };
        }
        return {};
      },
    }),
    PillsInput: TagsInput.extend({
      vars: (_theme, props) => {
        if (props.variant === 'white') {
          return {
            root: {
              '--input-bg': `rgba(0, 0, 0, 0.05)`,
              '--mantine-color-placeholder': `var(--mantine-color-${props.color || 'dark'}-6)`,
              '--input-section-color': `var(--mantine-color-${props.color || 'dark'}-6)`,
            },
            input: {
              background: `var(--mantine-color-white)`,
              color: `var(--mantine-color-${props.color || 'dark'}-6)`,
            },
          };
        }
        if (props.variant === 'dark-glass') {
          return {
            root: {
              '--input-bg': `rgba(0, 0, 0, 0.2)`,
              '--mantine-color-placeholder': 'rgba(255, 255, 255, 1)',
              '--input-section-color': 'rgba(255, 255, 255, 0.6)',
            },
          };
        }
        if (props.variant === 'glass') {
          if (props.color === 'white' || _theme.other?.colorScheme === 'dark') {
            return {
              root: {
                '--input-bg': `rgba(255, 255, 255, 0.1)`,
                '--mantine-color-placeholder': 'rgba(255, 255, 255, 0.45)',
                '--input-section-color': 'rgba(255, 255, 255, 0.6)',
                '--mantine-color-text': '#FFFFFF',
              },
            };
          }
          return {
            root: {
              '--input-bg': `rgba(0, 0, 0, 0.05)`,
              '--mantine-color-placeholder': 'rgba(0, 0, 0, 0.6)',
              '--input-section-color': 'rgba(0, 0, 0, 0.6)',
            },
          };
        }
        return {};
      },
    }),
    Input: Input.extend({
      defaultProps: {
        variant: 'filled',
      },
      classNames: {
        wrapper: 'pixi-Input-wrapper',
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'error', 'description'],
        styles: (theme) => ({
          label: {
            color:
              theme.other?.colorScheme === 'dark'
                ? 'red'
                : 'var(--mantine-color-dark-2)',
            marginBottom: 6,
          },
          description: {
            marginTop: 6,
          },
        }),
      },
      vars: (theme, props) => {
        if (props.variant === 'white') {
          return {
            root: {
              '--input-bg': `rgba(0, 0, 0, 0.05)`,
              '--mantine-color-placeholder': `var(--mantine-color-${props.color || 'dark'}-6)`,
              '--input-section-color': `var(--mantine-color-${props.color || 'dark'}-6)`,
            },
            input: {
              background: `var(--mantine-color-white)`,
              color: `var(--mantine-color-${props.color || 'dark'}-6)`,
            },
          };
        }
        if (props.variant === 'dark-glass') {
          return {
            root: {
              '--input-bg': `rgba(0, 0, 0, 0.2)`,
              '--mantine-color-placeholder': 'rgba(255, 255, 255, 1)',
              '--input-section-color': 'rgba(255, 255, 255, 0.6)',
            },
          };
        }
        if (props.variant === 'glass') {
          if (
            props.color === 'white' ||
            (props.variant === 'glass' && theme.other?.colorScheme === 'dark')
          ) {
            return {
              root: {
                '--input-bg': `rgba(255, 255, 255, 0.1)`,
                '--mantine-color-placeholder': 'rgba(255, 255, 255, 0.45)',
                '--input-section-color': 'rgba(255, 255, 255, 0.6)',
                '--mantine-color-text': '#FFFFFF',
              },
            };
          }
          return {
            root: {
              '--input-bg': `rgba(0, 0, 0, 0.05)`,
              '--mantine-color-placeholder': 'rgba(0, 0, 0, 0.6)',
              '--input-section-color': 'rgba(0, 0, 0, 0.6)',
            },
          };
        }
        return {};
      },
    }),
    Switch: Switch.extend({
      vars: (_theme, props) => {
        return {
          root: {
            '--switch-width': '30px',
          },
        };
      },
      styles: {
        root: {
          cursor: 'pointer',
        },
      },
    }),
    Title: {
      styles: {
        root: { fontWeight: 700 },
      },
      defaultProps: {
        size: 'h3',
      },
    },
    Button: Button.extend({
      vars: (_theme, props) => {
        const whiteGlassVariant = {
          '--button-bg': 'rgba(255, 255, 255, 0.1)',
          '--button-color': 'rgba(255, 255, 255, 1)',
          '--button-hover': 'rgba(255, 255, 255, 0.15)',
          '--button-border': 'rgba(255, 255, 255, 0.1)',
        };
        const glassVariant = {
          '--button-bg': 'rgba(0, 0, 0, 0.05)',
          '--button-color': 'rgba(0, 0, 0, 0.6)',
          '--button-hover': 'rgba(0, 0, 0, 0.05)',
          '--button-border': 'rgba(0, 0, 0, 0.05)',
        };
        if (props.size?.includes('wide-')) {
          const size = props.size.split('wide-')[1] as string;
          const sizeInRem = rem(
            size === 'xl'
              ? 56
              : size === 'lg'
                ? 44
                : size === 'md'
                  ? 38
                  : size === 'sm'
                    ? 34
                    : 24,
          );
          return {
            root: {
              ...(props.variant === 'glass'
                ? props.color === 'white' ||
                  _theme.other?.colorScheme === 'dark'
                  ? whiteGlassVariant
                  : glassVariant
                : {}),
              '--button-height': `var(--button-height-${size})`,
              '--button-padding-x': rem(sizeInRem),
              '--button-fz': `var(--mantine-font-size-${size})`,
            },
          };
        }
        if (
          (!props.variant || props.variant === 'filled') &&
          props.color === 'digital-forest'
        ) {
          return {
            root: {
              '--button-color': '#3c3b3b',
            },
          };
        }
        return {
          root: {
            ...(props.variant === 'glass'
              ? props.color === 'white' || _theme.other?.colorScheme === 'dark'
                ? whiteGlassVariant
                : glassVariant
              : {}),
            ...(props.variant === 'subtle' && props.color === 'white'
              ? {
                  '--button-hover': 'rgba(255, 255, 255, 0.15)',
                }
              : {}),
          },
        };
      },
      styles: (theme, props) => {
        return {
          root: {
            fontWeight: props.variant === 'outline' ? 400 : 500,
            borderWidth: 1,
          },
        };
      },
    }),
    Tooltip: {
      defaultProps: {
        transitionProps: {
          transition: TopCenterTransition,
        },
        withArrow: true,
        position: 'bottom',
      },
    },
    Menu: {
      defaultProps: {
        transitionProps: {
          transition: TopLeftTransition,
        },
        withinPortal: true,
        position: 'bottom-start',
        shadow: 'xl',
      } as MenuProps,
    },
    MenuItem: {
      defaultProps: {},
    },
    Select: Select.extend({
      defaultProps: {
        styles: {
          dropdown: { zIndex: 50 },
          label: {
            color: 'var(--mantine-color-dark-2)',
            marginBottom: 4,
          },
        },
      },
    }),
    ScrollAreaAutosize: ScrollAreaAutosize.extend({
      defaultProps: {
        className: 'pixi-ScrollAreaAutoSize',
      },
    }),
    InputWrapper: InputWrapper.extend({
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'error', 'description'],
        styles: {
          label: {
            color: 'var(--mantine-color-dark-2)',
            marginBottom: 12,
          },
          description: {
            marginTop: 12,
          },
        },
      },
    }),
  },
});

export function ThemeProvider() {}

export default function Theme({ children }: { children: ReactNode }) {
  const Router = useRouter();
  const isManage = Router.location.pathname?.startsWith('/manage');
  const appUser = useAppUser();
  const rawData = appUser.user;
  const communityColors = useMemo(() => {
    return rawData?.user?.communities?.reduce(
      (colorSchemes: {}, community: any) => ({
        ...colorSchemes,
        [community?.data?.communitySlug]: createColorShades(
          community?.data?.djangoCommunity?.color,
        ),
      }),
      {},
    );
  }, [rawData]);

  const colors = {
    ...theme.colors,
    ...communityColors,
  };
  const colorScheme = useColorScheme();

  return (
    <MantineProvider
      classNamesPrefix="pixi"
      forceColorScheme={colorScheme}
      defaultColorScheme={colorScheme}
      theme={{
        ...theme,
        other: {
          colorScheme,
        },
        primaryColor: !isManage
          ? rawData?.selectedCommunity?.communitySlug || 'pickit'
          : 'pickit',
        colors: {
          ...colors,
          primary:
            colors?.[
              !isManage
                ? rawData?.selectedCommunity?.communitySlug || 'pickit'
                : 'pickit'
            ],
        },
      }}
    >
      {children}
    </MantineProvider>
  );
}
