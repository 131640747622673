import {
  Badge,
  Box,
  Card,
  Checkbox,
  Divider,
  Group,
  Image,
  Pill,
  Stack,
  TagsInput,
  Textarea,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { AssetIcon, AssetThumbnail } from '@pixi/components/AssetThumbnail';
import SelectFolder from '@pixi/components/SelectFolder';
import PixiButton from '@pixi/elements/Button';
import PixiConfirm from '@pixi/elements/Confirm';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiForm from '@pixi/elements/Form';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import PixiTooltip from '@pixi/elements/Tooltip';
import { getMultiStore } from '@pixi/store';
import { useUserContext } from 'hooks';
import { createAppToast, openFilePanel } from '../../../AppController';
import { useAssetEditPanel } from '../context';
import { Detail, Section, SectionCollapse } from '../elements';
import SelectCollection from '@pixi/components/SelectCollection';
import TagsForm from '@pixi/components/AssetQuickForms/TagsForm';
import PropertyValueForm from 'views/_Manage/Library/workflows/Properties/PropertyValueForm';
import { format } from 'date-fns';
import { AssetPermissions } from '@pixi/Vars';
import PermissionForm from '@pixi/components/AssetQuickForms/PermissionForm';
import FeedbackForm from '@pixi/components/AssetQuickForms/FeedbackForm';
import { useAssetGridContext } from '@pixi/components/AssetGrid/AssetGridContext';
import { useContext, useState } from 'react';
import AssetEditPanelApprovals from './Sections/Approvals';
import AssetEditPanelProperties from './Sections/Properties';
import { Icon } from 'elements_v2';
import { useFileProvider } from 'contexts/File';
import useContextFromFile from 'hooks/useContextFromFile';
import { MediaContext } from 'contexts';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';

export default function AssetEditPanelMultiselect({
  onHide,
}: {
  onHide?: () => void;
}) {
  const AssetGrid = useAssetGridContext();
  const { files, onClose, context, isBrand } = useAssetEditPanel();
  const actions = useAssetActions({
    file: files[0],
    files,
    assetGridContext: AssetGrid,
  });
  const User = useUserContext();
  const allTags = files.reduce((tags: string[], file) => {
    return [...tags, ...file.tags];
  }, []);

  const activePermission = files?.every((file) => {
    return file.share_to === files?.[0]?.share_to;
  })
    ? AssetPermissions[files?.[0].share_to as keyof typeof AssetPermissions]
    : undefined;

  const approvalsCollapse = <AssetEditPanelApprovals />;

  const fileProvider: any = useContext(MediaContext);

  const reGenerateThumbnails = async (f: Pickit.FileInterface[]) => {
    try {
      f.forEach((file) => {
        fileProvider.reParseFile(file._id, {
          thumbnail: true,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box h="100%" style={{ overflow: 'auto' }} bg="white">
      <Stack gap="0" mah={300} style={{ overflow: 'auto' }}>
        <SectionCollapse
          label={<>{files.length} files selected</>}
          noChevron
          noDivider
          icon="circle-info"
          rightSection={
            <PixiButton
              size="xs"
              px="xs"
              color="dark"
              variant="subtle"
              style={{ flexShrink: 0 }}
              onClick={() => {
                onHide?.();
              }}
            >
              <PixiIcon name="arrow-right-from-line" />
            </PixiButton>
          }
        >
          {files.map((file) => (
            <Detail
              key={file._id}
              icon={<AssetIcon file={file} />}
              left={file.name}
              right={
                <PixiButton
                  color="gray"
                  variant="light"
                  size="xs"
                  px="xs"
                  onClick={() => {
                    getMultiStore('FILES_SELECTED', 'manageLibrary').remove(
                      file,
                    );
                  }}
                >
                  <PixiIcon name="xmark" />
                </PixiButton>
              }
            />
          ))}
        </SectionCollapse>
        <Divider />
        <Group gap="xs" wrap="nowrap" p="md">
          <PixiTooltip label="Download assets">
            <PixiButton
              color="dark"
              style={{
                flexShrink: 0,
              }}
              variant="glass"
              onClick={async () => {
                await context.downloadFilesAsZip(files);
              }}
            >
              <PixiIcon name="arrow-down-to-line" />
            </PixiButton>
          </PixiTooltip>

          {/* <PixiTooltip label="Remove files">
            <PixiConfirm
              title="Are you sure?"
              description="The selected files will be deleted permanently."
              confirmLabel="Delete files"
              confirmProps={{
                color: 'red',
              }}
              onConfirm={async () => {
                await context.deleteFiles(files.map((file) => file._id));
                onClose?.();
              }}
            >
              <PixiButton
                color="dark"
                style={{
                  flexShrink: 0,
                }}
                variant="glass"
              >
                <PixiIcon name="trash-can" />
              </PixiButton>
            </PixiConfirm>
          </PixiTooltip> */}

          {/* If we go wit this, make this to its own component... */}
          {actions?.getAction('move_to_trash')?.customRender?.(
            <PixiTooltip label="Move to trash">
              <PixiButton
                color="dark"
                style={{
                  flexShrink: 0,
                }}
                variant="glass"
              >
                <PixiIcon name="trash-can" />
              </PixiButton>
            </PixiTooltip>,
            {
              isOpen: false,
              setIsFreezeDropdown: () => {},
              onClose: () => {},
            },
          )}

          <PixiButton
            fullWidth
            variant="glass"
            color="dark"
            onClick={() => {
              onClose?.();
              onHide?.();
            }}
            leftSection={<PixiIcon name="xmark" />}
          >
            Clear selection
          </PixiButton>
        </Group>
        <Divider />
      </Stack>
      {!!AssetGrid?.activeShortcutIds?.find((f) => f?.includes('approvals')) &&
        approvalsCollapse}
      <SectionCollapse label="Tags">
        <Group gap="5">
          {allTags
            .filter((tag) => {
              return files.every((file) => file.tags.includes(tag));
            })
            .filter((tag, index, self) => self.indexOf(tag) === index)
            .map((tag) => (
              <Pill
                key={tag}
                withRemoveButton
                onRemove={() => {
                  context.saveDocumentsWhere(
                    {
                      _id: { $in: files.map((file) => file._id) },
                    },
                    {
                      $pull: {
                        tags: tag,
                      },
                    },
                  );
                }}
              >
                {tag}
              </Pill>
            ))}
        </Group>
        <TagsForm
          files={files}
          context={context}
          target={
            <PixiButton variant="light" leftSection={<PixiIcon name="tags" />}>
              Add tags
            </PixiButton>
          }
        />
      </SectionCollapse>
      {isBrand && (
        <SectionCollapse label="Folders">
          <SelectFolder
            context={context}
            onFolderClick={async (folder) => {
              await context.saveDocumentsWhere(
                {
                  _id: { $in: files.map((file) => file._id) },
                },
                {
                  $addToSet: {
                    folders: folder._id,
                  },
                },
              );
            }}
            target={
              <PixiButton
                variant="light"
                leftSection={<PixiIcon name="rectangle-history-circle-plus" />}
              >
                Add to folder
              </PixiButton>
            }
          />
        </SectionCollapse>
      )}
      {!isBrand && (
        <SectionCollapse label="Collections">
          {context.stores.data.collections
            .filter((c: Pickit.CollectionInterface) => {
              return files.every((file) => file.collections?.includes(c._id));
            })
            .map((col: Pickit.CollectionInterface) => (
              <Detail
                key={col._id}
                left={col.name}
                right={
                  <PixiConfirm
                    title="Remove from collection"
                    description="Selected files will be removed from the collection."
                    confirmLabel="Remove"
                    confirmProps={{
                      color: 'red',
                    }}
                    onConfirm={async () => {
                      await context.saveDocumentsWhere(
                        {
                          _id: { $in: files.map((file) => file._id) },
                        },
                        {
                          $pull: {
                            collections: col._id,
                          },
                        },
                      );
                    }}
                  >
                    <PixiButton size="xs" px="xs" variant="light" color="dark">
                      <PixiIcon name="xmark" />
                    </PixiButton>
                  </PixiConfirm>
                }
              />
            ))}
          <SelectCollection
            target={
              <PixiButton
                variant="light"
                leftSection={<PixiIcon name="rectangle-history-circle-plus" />}
              >
                Add to collection
              </PixiButton>
            }
            customFilter={(c) =>
              !files.every((file) => file.collections?.includes(c._id))
            }
            type={context.type}
            onCollectionClick={async (collection) => {
              await context.saveDocumentsWhere(
                {
                  _id: { $in: files.map((file) => file._id) },
                },
                {
                  $addToSet: {
                    collections: collection._id,
                  },
                },
              );
            }}
          />
        </SectionCollapse>
      )}
      <SectionCollapse label="Description">
        <PixiForm<{ description?: string }>
          type="dropdown"
          title="Set description"
          description="Selected files with a existing description will be overridden"
          form={{
            description: {
              key: 'description',
              value: '',
              render: ({ value, setValue }) => {
                return (
                  <Textarea
                    value={value}
                    onChange={(event) => {
                      setValue(event.currentTarget.value);
                    }}
                    placeholder="Description"
                    autoFocus
                  />
                );
              },
            },
          }}
          onSubmit={async ({ description }) => {
            await context.saveDocumentsWhere(
              {
                _id: { $in: files.map((file) => file._id) },
              },
              {
                description,
              },
            );
          }}
          position="bottom"
          width="target"
          target={
            <PixiButton
              variant="light"
              leftSection={<PixiIcon name="message" />}
            >
              Set description
            </PixiButton>
          }
        />
      </SectionCollapse>
      {!AssetGrid?.activeShortcutIds?.find((f) => f?.includes('approvals')) &&
        approvalsCollapse}
      <SectionCollapse label="Author">
        <PixiForm<{ author?: string }>
          type="dropdown"
          title="Set author"
          form={{
            author: {
              key: 'author',
              value: '',
              render: ({ value, setValue }) => {
                return (
                  <TextInput
                    value={value}
                    onChange={(event) => {
                      setValue(event.currentTarget.value);
                    }}
                    autoFocus
                  />
                );
              },
            },
          }}
          onSubmit={async ({ author }) => {
            await context.saveDocumentsWhere(
              {
                _id: { $in: files.map((file) => file._id) },
              },
              {
                author,
              },
            );
          }}
          position="bottom"
          width="target"
          target={
            <PixiButton variant="light" leftSection={<PixiIcon name="user" />}>
              Set author
            </PixiButton>
          }
        />
      </SectionCollapse>
      <AssetEditPanelProperties />
      {/* <SectionCollapse label="Access rights">
        <Detail
          left="Permission"
          right={
            activePermission && (
              <Group gap="xs" align="center">
                {activePermission?.label}
                {activePermission?.tooltip && (
                  <PixiTooltip label={activePermission?.tooltip}>
                    <PixiButton size="xs" px="xs" color="dark" variant="light">
                      <PixiIcon name="circle-info" size="xs" />
                    </PixiButton>
                  </PixiTooltip>
                )}
              </Group>
            )
          }
          rightProps={activePermission && activePermission?.rightProps}
        />
        <PermissionForm
          context={context}
          files={files}
          target={<PixiButton variant="light">Edit</PixiButton>}
        />
      </SectionCollapse>

      {AssetGrid.id !== 'manageLibrary_documents' && (
        <SectionCollapse label="Thumbnail">
          <PixiButton
            variant="light"
            leftSection={<Icon name="lightning-charge-fill" />}
            onClick={() => reGenerateThumbnails(files)}
          >
            Regenerate thumbnails
          </PixiButton>
        </SectionCollapse>
      )}
      {/* <SectionCollapse label="Feedback">
        <Group gap="5" />
        <FeedbackForm
          files={files}
          target={
            <PixiButton variant="light" leftSection={<PixiIcon name="tags" />}>
              Leave feedback
            </PixiButton>
          }
        />
      </SectionCollapse> */}
      {AssetGrid.id !== 'manageLibrary_documents' && (
        <SectionCollapse label="Thumbnail">
          <PixiButton
            variant="light"
            leftSection={<Icon name="lightning-charge-fill" />}
            onClick={() => reGenerateThumbnails(files)}
          >
            Regenerate thumbnails
          </PixiButton>
        </SectionCollapse>
      )}
    </Box>
  );
}
