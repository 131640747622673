import React, {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import PixiDropdown, { PixiDropdownProps } from '@pixi/elements/Dropdown';
import PixiContextMenu, {
  PixiContextMenuProps,
} from '@pixi/elements/ContextMenu';
import PixiIcon from '@pixi/elements/Icon';
import { ActionIcon, Box, Group, Text, Tooltip } from '@mantine/core';
import PixiTooltip from '@pixi/elements/Tooltip';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';
import { getDefaultAssetToolbar } from '@pixi/Vars';
import { isPromise } from '@pixi/helpers/utils';
import { useConfigStoreValue } from '@pixi/store';
import { AssetActionIds, AssetActionOptionOptionalId } from './Types';
import { useAssetGridContext } from '../AssetGrid/AssetGridContext';

export const AssetDropdownItem = forwardRef(
  (
    {
      file,
      action,
      setIsFreezeDropdown,
      onClose,
      isOpen,
      rootzIndex,
    }: {
      file: Pickit.FileInterface;
      action: AssetActionOptionOptionalId;
      setIsFreezeDropdown?: (isFreezeDropdown: boolean) => void;
      onClose?: () => void;
      isOpen: boolean;
      rootzIndex?: number;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const renderTrigger = (
      <Group wrap="nowrap" align="stretch" gap="1" ref={ref}>
        <PixiDropdown.Item
          onClick={
            (action.customRender && !action?.isCustomRenderWithoutClick) ||
            action.dropdownOptions?.length
              ? undefined
              : async (event) => {
                  const promise = action.onClick?.(event);
                  if (isPromise(promise)) {
                    await promise;
                  }
                  onClose?.();
                }
          }
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          icon={action.icon}
          iconProps={action.iconProps}
          color={action.color}
          disabled={action.disabled}
          withChevron={!!action.dropdownOptions?.length || !!action.withChevron}
          rightSection={
            <Text c="dimmed" size="xs">
              {action.subLabel}
            </Text>
          }
        >
          {action.label}
        </PixiDropdown.Item>
        {action.actions?.map((a) => {
          const item = (
            <PixiDropdown.Item
              styles={{
                itemSection: {
                  marginRight: 0,
                },
              }}
              style={{ flexShrink: 0 }}
              w="auto"
              key={a.icon}
              icon={a.icon}
              onClick={
                !a.render
                  ? (event) => {
                      a.onClick?.();
                    }
                  : undefined
              }
              px="5"
              iconProps={{
                ...(a.iconProps || {}),
                c: 'dimmed',
              }}
            />
          );

          // if (a?.tooltip) {
          //   item = (
          //     <Tooltip label={a.tooltip} key={item.key}>
          //       {item}
          //     </Tooltip>
          //   );
          // }
          return !a.render
            ? item
            : a.render(
                a.label ? (
                  <PixiTooltip label={a.label}>{item}</PixiTooltip>
                ) : (
                  item
                ),
                {
                  setIsFreezeDropdown,
                },
              );
        })}
      </Group>
    );

    if (action.customRender) {
      const render = useMemo(() => {
        return action?.customRender?.(renderTrigger, {
          isOpen,
          rootZIndex: rootzIndex,
          onClose: () => {
            onClose?.();
            setIsFreezeDropdown?.(false);
          },
          setIsFreezeDropdown: (isFreeze: boolean) => {
            setIsFreezeDropdown?.(isFreeze);
          },
        });
      }, []);
      return render;
    }
    return renderTrigger;
  },
);

export const AssetDropdownRender = ({
  file,
  filesInContext,
  actions,
  action,
  actionIds,
  actionDropdownOnly,
  onClose,
  isOpen,
  dropdownProps,
  setIsFreezeDropdown,
  rootzIndex,
}: {
  file: Pickit.FileInterface;
  filesInContext?: Pickit.FileInterface[];
  actions?: AssetActionOptionOptionalId[];
  actionIds?: AssetActionIds[];
  action?: AssetActionOptionOptionalId;
  actionDropdownOnly?: boolean;
  onClose?: () => void;
  isOpen: boolean;
  dropdownProps?: Partial<PixiDropdownProps>;
  setIsFreezeDropdown?: (isFreezeOpen: boolean) => void;
  rootzIndex?: number;
}) => {
  const viewport = useConfigStoreValue('APP_VIEW', 'viewport');
  const fileToolbar = useAssetActions({
    file,
    filesInContext,
  });
  if (actionIds) {
    if (!fileToolbar.specificActions(actionIds)?.length) {
      return <></>;
    }
  }
  if (!actions && !action) {
    return (
      <AssetDropdownRender
        file={file}
        actions={
          actionIds?.length
            ? fileToolbar.specificActions(actionIds)
            : fileToolbar.toolbar
        }
        onClose={onClose}
        dropdownProps={dropdownProps}
        isOpen={isOpen}
        rootzIndex={rootzIndex}
      />
    );
  }
  if (action) {
    actions = [action];
    if (actionDropdownOnly && action.dropdownOptions?.length) {
      return (
        <>
          {action.dropdownOptions?.map((group) => (
            <>
              {group.groupLabel && (
                <PixiDropdown.Label
                  key={group.groupLabel}
                  // icon={group.groupIcon}
                  info={group.groupInfo}
                >
                  {group.groupLabel}
                </PixiDropdown.Label>
              )}
              {group.withDivider && <PixiDropdown.Divider />}
              {group.actions.map((item) => (
                <AssetDropdownRender
                  key={item.id}
                  file={file}
                  action={item}
                  isOpen={isOpen}
                  setIsFreezeDropdown={setIsFreezeDropdown}
                  onClose={onClose}
                  rootzIndex={(rootzIndex || 1) + 1}
                />
              ))}
            </>
          ))}
        </>
      );
    }
  }

  return (
    <>
      {actions?.map((action) => {
        const item = (
          <AssetDropdownItem
            key={action.id}
            file={file}
            action={action}
            setIsFreezeDropdown={setIsFreezeDropdown}
            onClose={onClose}
            isOpen={isOpen}
            rootzIndex={(rootzIndex || 1) + 1}
          />
        );
        let trigger;

        if (action.dropdownOptions?.length) {
          trigger = (
            <PixiDropdown
              width={action.width || 250}
              trigger={
                viewport === 'xxs' || viewport === 'xs' ? 'click' : 'hover'
              }
              key={action.id}
              // forceOpen={isFreezeDropdown}
              position="right-start"
              offset={5}
              zIndex={(rootzIndex || 10) + 1}
              target={({ isOpen, setIsFreezeOpen }) => (
                <Box w="100%">
                  <AssetDropdownItem
                    key={action.id}
                    file={file}
                    action={action}
                    isOpen={isOpen}
                    setIsFreezeDropdown={(isFreeze) => {
                      setIsFreezeOpen(isFreeze);
                      setIsFreezeDropdown?.(isFreeze);
                    }}
                    rootzIndex={(rootzIndex || 1) + 1}
                    onClose={onClose}
                  />
                </Box>
              )}
              closeOnItemClick={false}
              customRender={({ isOpen, setIsOpen, setIsFreezeOpen }) => (
                <>
                  {action?.dropdownOptions?.map((group) => (
                    <>
                      {group.groupLabel && (
                        <PixiDropdown.Label
                          // icon={group.groupIcon}
                          info={group.groupInfo}
                        >
                          {group.groupLabel}
                        </PixiDropdown.Label>
                      )}
                      {group.withDivider && <PixiDropdown.Divider />}
                      {group.actions.map((item) => (
                        <AssetDropdownRender
                          key={item.id}
                          action={item}
                          onClose={() => {
                            setIsOpen(false);
                            onClose?.();
                          }}
                          rootzIndex={(rootzIndex || 1) + 1}
                          setIsFreezeDropdown={(isFreeze) => {
                            setIsFreezeOpen(isFreeze);
                            setIsFreezeDropdown?.(isFreeze);
                          }}
                          isOpen={isOpen}
                          file={file}
                        />
                        // <AssetDropdownItem
                        //   key={item.id}
                        //   file={file}
                        //   isOpen={isOpen}
                        //   action={item}
                        //   setIsFreezeDropdown={(isFreeze) => {
                        //     setIsFreezeOpen(isFreeze);
                        //     setIsFreezeDropdown?.(isFreeze);
                        //   }}
                        //   onClose={() => {
                        //     setIsOpen(false);
                        //     onClose?.();
                        //   }}
                        // />
                      ))}
                    </>
                  ))}
                </>
              )}
              {...dropdownProps}
            />
          );
        } else {
          trigger = item;
        }
        return action.customRender
          ? action.customRender(trigger, {
              isOpen,
              onClose: () => {
                onClose?.();
              },
              rootZIndex: rootzIndex,
              setIsFreezeDropdown: () => {},
            })
          : trigger;
      })}
    </>
  );
};

export const AssetDropdown = ({
  file,
  onTrigger,
  target,
  items,
  children,
}: {
  file: Pickit.FileInterface;
  onlyOptions?: boolean;
  onTrigger?: (isOpen: boolean) => void;
  target: React.ReactElement;
  disableOptions?: string[];
  items?: AssetActionIds[];
  children?: ReactNode;
}) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const { toolbar, specificActions } = useAssetActions({
    file,
  });

  useEffect(() => {
    onTrigger?.(isContextMenuOpen);
  }, [isContextMenuOpen]);

  return (
    <>
      <PixiDropdown target={target}>
        <AssetDropdownRender
          file={file}
          isOpen={isContextMenuOpen}
          onClose={() => {
            setIsContextMenuOpen(false);
          }}
          actions={!items ? toolbar : specificActions(items, true)}
        />
        {children}
      </PixiDropdown>
    </>
  );
};

export const AssetContextMenu = ({
  file,
  onTrigger,
  contextMenuRef,
  contextMenuProps,
  readOnly,
  ...rest
}: {
  file: Pickit.FileInterface;
  onlyOptions?: boolean;
  onTrigger?: (isOpen: boolean) => void;
  contextMenuRef: React.RefObject<HTMLElement>;
  disableOptions?: string[];
  readOnly?: boolean;
  onPreviewFile?: (file: Pickit.FileInterface) => void;
  downloadWrapper?: (
    trigger: React.ReactElement,
    onClick: () => void,
    label?: string,
  ) => React.ReactElement;
  contextMenuProps?: Omit<PixiContextMenuProps, 'children' | 'element'>;
} & Partial<PixiDropdownProps>) => {
  const AssetGrid = useAssetGridContext();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const { specificActions } = useAssetActions({
    file,
    assetGridContext: AssetGrid,
    dropdownProps: {
      zIndex: rest?.zIndex ? parseInt(rest.zIndex.toString()) + 1 : 11,
    },
  });

  useEffect(() => {
    onTrigger?.(isContextMenuOpen);
    if (!isContextMenuOpen) {
      // setIsFreezeDropdown(false);
    }
  }, [isContextMenuOpen]);

  return (
    <>
      <PixiContextMenu
        zIndex={10}
        {...(contextMenuProps || {})}
        element={contextMenuRef}
        isOpen={isContextMenuOpen}
        alwaysCloseOnClickOutside
        onTrigger={(isOpen) => {
          setIsContextMenuOpen(isOpen);
        }}
        keepMounted={false}
        {...rest}
      >
        {({ setIsFreezeOpen, isFreezeOpen, isOpen, setIsOpen }) => (
          <AssetDropdownRender
            isOpen={isOpen}
            rootzIndex={rest.zIndex ? parseInt(rest.zIndex?.toString()) : 10}
            setIsFreezeDropdown={setIsFreezeOpen}
            actions={specificActions(
              getDefaultAssetToolbar(file, {
                readOnly,
              }),
              false,
            )}
            onClose={() => {
              setIsContextMenuOpen(false);
            }}
            file={file}
          />
        )}
      </PixiContextMenu>
    </>
  );
};

export const AssetToolbar = ({
  file,
  actions,
  disableActions,
  onAssetActionStart,
  onAssetActionEnd,
  specificActions: _specificActions,
}: {
  file: Pickit.FileInterface;
  includeDownloadBag?: boolean;
  includePreviewAsset?: boolean;
  actions?: string[];
  disableActions?: string[];
  onAssetActionStart?: () => void;
  onAssetActionEnd?: () => void;
  specificActions?: AssetActionIds[];
}) => {
  const {
    toolbar: data,
    isDoingAssetAction,
    specificActions,
  } = useAssetActions({
    file,
  });

  React.useEffect(() => {
    if (isDoingAssetAction) {
      onAssetActionStart?.();
    } else {
      onAssetActionEnd?.();
    }
  }, [isDoingAssetAction]);

  return (_specificActions ? specificActions(_specificActions, false) : data)
    ?.filter((item) => {
      if (!item.id) {
        return false;
      }
      if (disableActions?.length) {
        return !disableActions.includes(item.id);
      }
      if (actions?.length) {
        return actions.includes(item.id);
      }
      return true;
    })
    .map((item) => {
      const icon = (
        <ActionIcon
          onClick={(event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            item.onClick?.(event);
          }}
        >
          <PixiIcon name={item.icon || '00'} {...(item.iconProps || {})} />
        </ActionIcon>
      );

      if (item?.dropdownOptions?.length) {
        return (
          <PixiDropdown target={icon} key={item.id}>
            {(prefs) => (
              <AssetDropdownRender
                file={file}
                action={item}
                isOpen={prefs.isOpen}
                setIsFreezeDropdown={prefs.setIsFreezeOpen}
                actionDropdownOnly
              />
            )}
          </PixiDropdown>
        );
      }

      return (
        <Tooltip key={item.id} position="bottom" label={item.label}>
          {item.customRender
            ? item.customRender(icon, {
                isOpen: true,
                onClose: () => {},
                setIsFreezeDropdown: () => {},
              })
            : icon}
        </Tooltip>
      );
    });
};
