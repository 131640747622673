import { Badge, Card, CardProps, Divider, Group, Stack } from '@mantine/core';
import { SectionCollapse, Title } from '@pixi/components/AssetPanel/elements';
import PixiButton from '@pixi/elements/Button';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import PixiText, { PixiTitle } from '@pixi/elements/Text';
import PixiTooltip from '@pixi/elements/Tooltip';
import { useState } from 'react';
import { useAssetGridContext } from '../AssetGridContext';
import {
  AssetGridContextInterface,
  ShortcutDataRow,
  ShortcutGroupIds,
  ShortcutIds,
  ShortcutRow,
} from '../Helpers';

export default function AssetGridFilterShortcuts({
  onClose,
  specificShortcuts,
  forceShortcutGroup,
}: {
  onClose: () => void;
  specificShortcuts?: ShortcutIds[];
  forceShortcutGroup?: ShortcutGroupIds;
}) {
  const {
    setFilter,
    setFilters,
    shortcuts,
    activeShortcutIds,
    activeShortcuts,
    toggleActiveShortcut,
    activeShortcutsData,
  } = useAssetGridContext();

  function renderShortcutData(
    row: ShortcutDataRow,
    shortcutGroup: ShortcutRow,
    props?: Partial<CardProps>,
  ) {
    const isActive = activeShortcutIds?.includes(row.id);
    return (
      <Card
        withBorder
        color={isActive ? 'primary' : undefined}
        key={row.id}
        {...props}
      >
        <Stack gap="xs">
          <Stack gap="xs">
            <Group w="100%" justify="space-between">
              <Group gap="xs">
                {row.icon ? <PixiIcon name={row.icon} size="lg" /> : <></>}
                <PixiText>{row.label}</PixiText>
              </Group>
              {isActive ? (
                <Badge color="primary" variant="light">
                  ACTIVE
                </Badge>
              ) : (
                <></>
              )}
            </Group>
          </Stack>
          {isActive ? (
            <PixiButton
              onClick={() => {
                row.revert?.();
                toggleActiveShortcut({ id: row.id, group: shortcutGroup });
              }}
              variant="primary"
              size="xs"
            >
              Clear
            </PixiButton>
          ) : (
            <PixiButton
              variant="light"
              color="dark"
              onClick={() => {
                toggleActiveShortcut({ id: row.id, group: shortcutGroup });
              }}
              size="xs"
            >
              {row.buttonLabel || 'Apply'}
            </PixiButton>
          )}
        </Stack>
      </Card>
    );
  }

  if (
    forceShortcutGroup &&
    shortcuts.find((group) => group.id === forceShortcutGroup)
  ) {
    return (
      <Stack gap="xs">
        {shortcuts
          .find((group) => group.id === forceShortcutGroup)
          ?.data.filter((row) => {
            if (specificShortcuts) {
              return specificShortcuts?.includes(row.id);
            }
            return true;
          })
          .map((row) =>
            renderShortcutData(
              row,
              shortcuts.find(
                (group) => group.id === forceShortcutGroup,
              ) as ShortcutRow,
            ),
          )}
      </Stack>
    );
  }
  return (
    <>
      {shortcuts
        .filter((group) => {
          if (specificShortcuts) {
            return group.data.find((row) =>
              specificShortcuts?.includes(row.id),
            );
          }
          if (group.hidden) {
            return false;
          }
          return true;
        })
        .map((group) => (
          <SectionCollapse
            key={group.label}
            label={group.label}
            // icon={group.icon}
            padding="md"
            forceOpen={
              !!group.data?.find((row) =>
                activeShortcuts?.includes({ id: row.id }),
              )
            }
          >
            <Stack gap="xs">
              {group.data
                .filter((row) => {
                  if (specificShortcuts) {
                    return specificShortcuts?.includes(row.id);
                  }
                  return true;
                })
                .map((row) => renderShortcutData(row, group))}
            </Stack>
          </SectionCollapse>
        ))}
    </>
  );
}
