import {
  Anchor,
  Box,
  Divider,
  Group,
  JsonInput,
  Paper,
  ScrollArea,
  ScrollAreaAutosize,
  Stack,
} from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiButtonLink from '@pixi/elements/Button/Link';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import PixiTooltip from '@pixi/elements/Tooltip';
import { getMultiStore } from '@pixi/store';
import { ReactNode, useState } from 'react';
import { AssetIcon } from '../AssetThumbnail';
import { Detail, Section, SectionCollapse } from './elements';
import AssetEditPanelOverview from './components/Overview';
import AssetEditPanelProvider, {
  AssetEditPanelContextInterface,
  useAssetEditPanel,
} from './context';
import AssetEditPanelIndexing from './components/Indexing';
import AssetEditPanelSharing from './components/Sharing';
import AssetEditPanelMultiselect from './components/MultiSelect';
import { AssetDropdownItem, AssetDropdownRender } from '../AssetActions';
import { useAssetGridContext } from '../AssetGrid/AssetGridContext';
import TrashView from './components/TrashView';
import AssetSpecificActions from './components/Sections/AssetSpecificActions';

export interface AssetEditPanelProps {
  files?: Pickit.FileInterface[];
  disable?: AssetEditPanelContextInterface['disable'];
  focus?: AssetEditPanelContextInterface['focus'];
  onClose?: () => void;
  onHide?: (isForceHidden?: boolean) => void;
}

export default function AssetEditPanel({
  files,
  onClose,
  onHide,
  disable,
  focus,
}: AssetEditPanelProps) {
  if (!files?.length) {
    return <></>;
  }
  return (
    <AssetEditPanelProvider
      files={files}
      onClose={onClose}
      onHide={onHide}
      disable={disable}
      focus={focus}
    >
      <AssetEditPanelRender />
    </AssetEditPanelProvider>
  );
}

function AssetEditPanelRender() {
  const {
    files,
    file,
    disabledSections,
    disable,
    onClose,
    fileToolbar,
    scanMetadata,
    onHide,
  } = useAssetEditPanel();
  const [activeTab, setActiveTab] = useState('overview');
  const AssetGrid = useAssetGridContext();

  const tabs: { label: ReactNode; id: string; icon: PixiIconName }[] = [
    {
      label: 'Overview',
      id: 'overview',
      icon: 'circle-info',
    },
    {
      label: 'Indexing',
      id: 'indexing',
      icon: 'magnifying-glass',
    },
    ...(!disabledSections?.includes('sharing')
      ? ([
          {
            label: 'Publishing',
            id: 'sharing',
            icon: 'share',
          },
        ] as { label: ReactNode; id: string; icon: PixiIconName }[])
      : []),
  ];

  if (!file) {
    return <></>;
  }

  if (AssetGrid.activeShortcutIds?.find((c) => c?.includes('trash'))) {
    return <TrashView onHide={onHide} />;
  }

  if (files?.length > 1) {
    return <AssetEditPanelMultiselect onHide={onHide} />;
  }

  return (
    <>
      <Stack w="100%" h="100%" gap="0" bg="white" autoFocus>
        {!disable?.includes('header') && (
          <Stack gap="0">
            <SectionCollapse
              label={
                !files.length && file ? (
                  file?.name
                ) : (
                  <>{files.length} files selected</>
                )
              }
              noChevron
              onToggle={() => {
                onHide?.(true);
              }}
              rightSection={
                <>
                  <PixiTooltip
                    label={
                      !files.length ? 'Deselect file' : 'Deselect all files'
                    }
                  >
                    <PixiButton
                      style={{ flexShrink: 0 }}
                      variant="subtle"
                      color="dark"
                      size="xs"
                      px="xs"
                      onClick={() => {
                        onClose?.();
                        onHide?.();
                      }}
                    >
                      <PixiIcon name="xmark" size="lg" />
                    </PixiButton>
                  </PixiTooltip>
                </>
              }
            />
            <Group gap="xs" wrap="nowrap" p="md">
              {fileToolbar
                .specificActions([
                  'download',
                  'download_external',
                  'open_external',
                  'upload_new_version',
                  'preview',
                  'advanced_settings',
                  'transfers',
                  'move_to_trash',
                ])
                .map((button) => {
                  let trigger = (
                    <PixiButton
                      onClick={(event) => button.onClick?.(event)}
                      color={button.iconProps?.color || 'dark'}
                      variant={
                        button.iconProps?.variant === 'filled'
                          ? 'filled'
                          : 'glass'
                      }
                      px="sm"
                      fullWidth
                      title={button.label as string}
                    >
                      <PixiIcon
                        name={button.icon || '00'}
                        variant={button.iconProps?.variant}
                      />
                    </PixiButton>
                  );

                  if (button.dropdownOptions?.length) {
                    trigger = (
                      <PixiDropdown
                        width={button.width || 240}
                        position="bottom-end"
                        target={trigger}
                        key={button.id}
                      >
                        {({ isOpen, setIsFreezeOpen, setIsOpen }) => (
                          <>
                            {button.dropdownOptions?.map((group) => (
                              <>
                                {group.groupLabel && (
                                  <PixiDropdown.Label
                                    key={group.groupLabel}
                                    icon={group.groupIcon}
                                  >
                                    {group.groupLabel}
                                  </PixiDropdown.Label>
                                )}
                                {group.withDivider && <PixiDropdown.Divider />}
                                <AssetDropdownRender
                                  isOpen={isOpen}
                                  setIsFreezeDropdown={setIsFreezeOpen}
                                  file={file}
                                  actions={group.actions}
                                />
                                {/* {group.actions.map((item) => (
                                  <AssetDropdownItem
                                    key={item.id}
                                    action={item}
                                    file={files[0]}
                                    isOpen={isOpen}
                                    setIsFreezeDropdown={setIsFreezeOpen}
                                    onClose={() => setIsOpen(false)}
                                  />
                                ))} */}
                              </>
                            ))}
                          </>
                        )}
                      </PixiDropdown>
                    );
                  }
                  return button.customRender
                    ? button.customRender(trigger, {
                        isOpen: true,
                        setIsFreezeDropdown: () => {},
                        onClose: () => {},
                      })
                    : trigger;
                })}
            </Group>
            <AssetSpecificActions />
          </Stack>
        )}
        {!disable?.includes('header') && <Divider />}
        <Section>
          <Group wrap="nowrap" gap="5" justify="flex-start">
            {tabs.map((tab) => {
              return (
                <Anchor
                  c={activeTab === tab.id ? 'dark' : 'gray.5'}
                  key={tab.id}
                  onClick={() => {
                    setActiveTab(tab.id);
                  }}
                  fw="600"
                  mr="xl"
                >
                  {tab.label}
                </Anchor>
              );
            })}
          </Group>
        </Section>
        <Box w="100%" h="100%" mih={1}>
          <ScrollArea w="100%" h="100%" mih={1}>
            <Box w="100%" style={{ overflowY: 'visible', overflowX: 'hidden' }}>
              <Group
                w={`${tabs.length}00%`}
                style={{
                  transform: `translateX(${
                    (-100 / tabs.length) *
                    tabs.findIndex((tab) => tab.id === activeTab)
                  }%)`,
                  transition: 'transform 0.15s',
                }}
                align="flex-start"
                wrap="nowrap"
                gap="0"
              >
                <Box
                  w="100%"
                  style={{
                    transform: `scale(${activeTab === 'overview' ? 1 : 0.8})`,
                    transition: 'transform 0.1s',
                  }}
                >
                  {activeTab === 'overview' && <AssetEditPanelOverview />}
                </Box>
                <Box
                  w="100%"
                  miw={1}
                  style={{
                    transform: `scale(${activeTab === 'indexing' ? 1 : 0.8})`,
                    transition: 'transform 0.1s',
                  }}
                >
                  {activeTab === 'indexing' && <AssetEditPanelIndexing />}
                </Box>
                {!!tabs.find((t) => t.id === 'sharing') && (
                  <Box
                    w="100%"
                    style={{
                      transform: `scale(${activeTab === 'sharing' ? 1 : 0.8})`,
                      transition: 'transform 0.1s',
                    }}
                  >
                    {activeTab === 'sharing' && <AssetEditPanelSharing />}
                  </Box>
                )}
              </Group>
            </Box>
          </ScrollArea>
        </Box>
        <Paper mt="auto" pos="sticky" bottom={0}>
          <Divider />
          <Group w="100%" justify="flex-end" p="5">
            <PixiDropdown
              position="top-end"
              target={
                <PixiButton size="compact-xs" variant="subtle" color="gray">
                  <PixiIcon name="ellipsis" />
                </PixiButton>
              }
            >
              {({ setIsOpen }) => (
                <>
                  <PixiDropdown.Item
                    icon="rotate"
                    onClick={async () => {
                      await scanMetadata();
                      setIsOpen(false);
                    }}
                  >
                    Regenerate metadata
                  </PixiDropdown.Item>
                  <PixiDropdown
                    width={900}
                    height={600}
                    target={
                      <PixiDropdown.Item icon="database">
                        Raw data
                      </PixiDropdown.Item>
                    }
                  >
                    <JsonInput
                      styles={{
                        input: {
                          height: 500,
                        },
                      }}
                      value={JSON.stringify(file, null, 2)}
                    />
                  </PixiDropdown>
                </>
              )}
            </PixiDropdown>
          </Group>
        </Paper>
      </Stack>
    </>
  );
}
