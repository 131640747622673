import {
  ActionIcon,
  Box,
  Card,
  Group,
  Notification,
  Stack,
  Text,
  ThemeIcon,
  Transition,
} from '@mantine/core';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import {
  DataStoresList,
  getStore,
  useDataStoreCallback,
  useDataStoreListener,
} from '@pixi/store';
import { useEffect, useReducer } from 'react';

export default function AppToasts() {
  const toasts = useDataStoreCallback('APP_TOASTS', (data) => data);

  return (
    <Box pos="fixed" bottom={0} right={0} style={{ zIndex: 999 }} p="xl">
      <Stack>
        {toasts?.map((toast) => {
          const color = toast.type === 'error' ? 'white' : 'gray';
          return (
            <Transition
              key={toast.id}
              mounted={!!toast.visible}
              transition="pop-bottom-right"
              duration={150}
              timingFunction="ease"
            >
              {(style) => (
                <Card
                  style={style}
                  miw={300}
                  maw={500}
                  p="xs"
                  radius="xl"
                  bg={toast.type === 'error' ? 'red' : ''}
                  c={toast.type === 'error' ? 'white' : 'dimmed'}
                >
                  <Group w="100%" gap="lg" align="center" wrap="nowrap">
                    <ThemeIcon
                      radius="xl"
                      color={color}
                      variant="light"
                      size="lg"
                    >
                      <PixiIcon
                        name={
                          toast.icon ||
                          (toast.type === 'success'
                            ? 'check'
                            : toast.type === 'error'
                              ? 'x'
                              : 'bell') ||
                          'bell'
                        }
                      />
                    </ThemeIcon>
                    <Stack w="100%" gap="5">
                      {toast.title && (
                        <PixiText size="sm">{toast.title}</PixiText>
                      )}
                      {toast.message && (
                        <PixiText size="sm">{toast.message}</PixiText>
                      )}
                    </Stack>
                    <ActionIcon
                      onClick={() => {
                        getStore('APP_TOASTS').removeByKey(toast.id);
                      }}
                      variant="subtle"
                      color={color}
                    >
                      <PixiIcon name="xmark" />
                    </ActionIcon>
                  </Group>
                </Card>
              )}
            </Transition>
          );
        })}
      </Stack>
    </Box>
  );
}
