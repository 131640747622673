import {
  ReactElement,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import {
  Paper,
  PaperProps,
  Group,
  ScrollAreaAutosize,
  Stack,
  Text,
  Divider,
  Box,
} from '@mantine/core';
import PixiDropdown, { PixiDropdownProps } from '../Dropdown';
import { useFocusTrap } from '@mantine/hooks';
import PixiButton from '../Button';
import PixiIcon, { PixiIconName } from '../Icon';
import {
  PixiDropdownRenderProps,
  useDropdownContext,
} from '../Dropdown/useDropdownContext';
import { rest } from 'lodash';

export type PixiDropdownFormItem<Key, Value, Form> = {
  key: Key;
  value: Value;
  render: ({
    value,
    setValue,
    data,
    setData,
    dropdown,
  }: {
    value: Value;
    setValue: (newValue: Value) => void;
    data: Form;
    setData: (data: Partial<Form>) => void;
    dropdown: PixiDropdownRenderProps;
  }) => ReactNode;
};

interface PixiCommonProps<T> {
  title?: ReactNode;
  type: 'paper' | 'dropdown';
  largeTitle?: boolean;
  description?: ReactNode;
  icon?: PixiIconName;
  onSubmit?: (data: T) => void | Promise<void>;
  form: Partial<{
    [K in keyof T]: PixiDropdownFormItem<K, T[K], T>;
  }>;
  submitLabel?: ReactNode | ((form: T) => ReactNode);
  injectBeforeForm?: ReactNode;
  injectLeft?: ReactNode;
  customActions?: (props: {
    data: T;
    setData: (data: T) => void;
    submit: () => unknown | Promise<unknown>;
    setIsOpen: (isOpen: boolean) => void;
  }) => ReactNode;
  submitProps?:
    | React.ComponentProps<typeof PixiButton>
    | ((form: T) => React.ComponentProps<typeof PixiButton>);
  paperProps?: PaperProps;
  withCancel?: boolean;
  onCancel?: () => void;
}
interface PixiPaperFormProps<T> extends PixiCommonProps<T> {
  type: 'paper';
  paperProps?: PaperProps;
}

interface PixiDropdownFormProps<T>
  extends PixiCommonProps<T>,
    Partial<Omit<PixiDropdownProps, 'title' | 'onSubmit'>> {
  type: 'dropdown';
  dropdownProps?: Omit<PixiDropdownProps, 'title' | 'onSubmit'>;
  onOpen?: () => void;
  target: ReactElement;
}

export type PixiFormProps<T> = PixiPaperFormProps<T> | PixiDropdownFormProps<T>;

function PixiFormRender<T extends Record<string, unknown>>({
  type,
  title,
  largeTitle,
  description,
  icon,
  submitLabel,
  submitProps,
  form,
  injectBeforeForm,
  injectLeft,
  onSubmit,
  isOpen,
  setIsOpen,
  customActions,
  withCancel,
  onCancel,
  ...rest
}: PixiCommonProps<T> & {
  type?: 'dropdown' | 'paper';
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const dropdown = useDropdownContext();
  const defaultValues = Object.values(form).reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {} as T);
  const [isLoading, setIsLoading] = useState(false);
  const focusTrapRef = useFocusTrap(isOpen);
  const [data, setData] = useState<T>(defaultValues || ({} as T));
  const setValue = <Key extends keyof T>(key: Key, value: T[Key]) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  useEffect(() => {
    const defaultValues = Object.values(form).reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {} as T);
    setData(defaultValues);
  }, []);

  const handleSubmit = async () => {
    if (onSubmit) {
      setIsLoading(true);
      await onSubmit(data);
      setIsLoading(false);
      setIsOpen(false);
      setData({ ...defaultValues });
    }
  };

  const formRender = (
    <form
      ref={focusTrapRef}
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleSubmit();
      }}
    >
      <Stack gap="xs">
        {Object.values(form).map(
          (item: PixiDropdownFormItem<keyof T, T[keyof T], T>) =>
            item.render({
              value: data[item.key],
              setValue: (newValue: T[typeof item.key]) =>
                setValue(item.key, newValue),
              data,
              setData: (data: Partial<T>) => {
                setData((prevData) => {
                  return { ...prevData, ...data };
                });
              },
              dropdown,
            }),
        )}
      </Stack>
      {type === 'paper' ? <Divider my="md" /> : <></>}
      {description && (
        <Paper withBorder bg="gray.0" radius="sm" p="xs" mt="sm">
          <Text c="dimmed" size="xs" fw="500">
            {description}
          </Text>
        </Paper>
      )}
      <Group w="100%" wrap="nowrap" justify="space-between" mt="sm">
        {customActions ? (
          customActions?.({
            data,
            setData,
            submit: () => onSubmit?.(data),
            setIsOpen,
          })
        ) : (
          <>
            {withCancel && !!onCancel && (
              <PixiButton
                fullWidth
                onClick={() => onCancel()}
                variant="light"
                color="dark"
              >
                Cancel
              </PixiButton>
            )}
            <PixiButton
              fullWidth
              type="submit"
              loading={isLoading}
              {...(typeof submitProps === 'function'
                ? submitProps(data)
                : submitProps || {})}
            >
              {typeof submitLabel === 'function'
                ? submitLabel(data)
                : submitLabel || 'Save changes'}
            </PixiButton>
          </>
        )}
      </Group>
    </form>
  );

  return (
    <Stack p="lg" gap="md">
      {title && (
        <PixiDropdown.Title icon={icon} large={largeTitle}>
          {title}
        </PixiDropdown.Title>
      )}
      {injectLeft ? (
        <Group wrap="nowrap">
          {injectLeft}
          <Box>
            {injectBeforeForm}
            {formRender}
          </Box>
        </Group>
      ) : (
        <>
          {injectBeforeForm}
          {formRender}
        </>
      )}
    </Stack>
  );
}

function PixiForm<T extends Record<string, unknown>>(
  props: PixiFormProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    type,
    title,
    largeTitle,
    description,
    icon,
    submitLabel,
    submitProps,
    form,
    injectBeforeForm,
    onSubmit,
    paperProps,
    onCancel,
    ..._rest
  } = props;

  const defaultValues = Object.values(form).reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {} as T);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<T>(defaultValues || ({} as T));

  useEffect(() => {
    const defaultValues = Object.values(form).reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {} as T);
    setData(defaultValues);
  }, [form]);
  if (type === 'paper') {
    return (
      <Paper shadow="0" {...paperProps}>
        <PixiFormRender<T>
          type={type}
          largeTitle={largeTitle}
          onSubmit={onSubmit}
          title={title}
          submitLabel={submitLabel}
          icon={icon}
          injectBeforeForm={injectBeforeForm}
          form={form}
          submitProps={submitProps}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onCancel={onCancel}
          {..._rest}
        />
      </Paper>
    );
  }

  if (type === 'dropdown') {
    const { target } = _rest as any;
    if (!target) {
      throw new Error('Target is required for dropdown type');
    }

    const rest = _rest as Partial<
      Omit<PixiDropdownProps, 'title' | 'onSubmit'>
    >;

    return (
      <PixiDropdown
        width={300}
        {...rest}
        opened={isOpen}
        target={target}
        onOpen={() => {
          setIsOpen(true);
          rest?.onOpen?.();
        }}
        ref={ref}
        onClose={(reason) => {
          setIsOpen(false);
          rest?.onClose?.(reason);
        }}
        boxProps={{
          style: {
            overflow: 'visible',
          },
        }}
        zIndex={rest.zIndex || 25}
        height={undefined}
      >
        {({ setIsOpen }) => (
          <Stack gap="xs">
            <ScrollAreaAutosize h={rest?.height}>
              <PixiFormRender<T>
                type={type}
                onSubmit={onSubmit}
                title={title}
                largeTitle={largeTitle}
                description={description}
                submitLabel={submitLabel}
                icon={icon}
                injectBeforeForm={injectBeforeForm}
                form={form}
                submitProps={submitProps}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onCancel={() => {
                  setIsOpen(false);
                  onCancel?.();
                }}
                {..._rest}
              />
            </ScrollAreaAutosize>
          </Stack>
        )}
      </PixiDropdown>
    );
  }

  return null;
}

export default PixiForm;
