import React, { useEffect, useReducer, useState } from 'react';
import { differenceInHours, isAfter } from 'date-fns';
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { useUserContext } from 'hooks/useUserContext';

import asyncPool from 'utils/async-pool';

const INTEGRATION = 'dropbox-export';
const INTEGRATION_NAME = 'Dropbox';

export default function useDropboxExport(props) {
  const { Dropbox } = props;
  const DAILY_SYNC_INTERVAL_HOURS = 4;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();
  const User = useUserContext();
  const [rawJobs, setRawJobs] = useReducer((state, action) => [...action], []);
  const [jobs, setJobs] = useReducer((state, action) => {
    const stateCopy = JSON.parse(JSON.stringify(state));
    if (Array.isArray(action)) {
      return [...action];
    }
    if (action?.type === 'UPDATE_STATUS') {
      return stateCopy.map((j) =>
        j._id === action.jobId
          ? { ...j, status: { ...j.status, ...action.status } }
          : j,
      );
    }
    if (action?.type === 'UPDATE_FILE_STATUS') {
      return stateCopy.map((j) =>
        j._id === action.jobId
          ? {
              ...j,
              status: {
                ...j.status,
                files: {
                  ...(j.status?.files || {}),
                  [action.fileId]: action.status,
                },
              },
            }
          : j,
      );
    }
  }, []);

  const [forceJob, setForceJob] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [jobQueue, setJobQueue] = useState([]);
  const [isRunningJobs, setIsRunningJobs] = useState(false);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const [isDisconnectedWarning, setIsDisconnectedWarning] = useState(false);

  useEffect(() => {
    if (jobs.length) {
      setJobs(
        jobs.map((job) => {
          return {
            ...job,
          };
        }),
      );
    }
  }, [Media?.data?.collections?.length, Documents?.data?.collections?.length]);

  useEffect(() => {
    if (jobQueue?.length && !isRunningJobs) {
      runJobQueue();
    }
  }, [jobQueue, isRunningJobs]);

  useEffect(() => {
    if (jobs.length && Dropbox.isConnected) {
      const notChecked = jobs.filter((job) => {
        return (
          (!job.status || (!job.status?.isRunning && !job.status?.isChecked)) &&
          !jobQueue.includes(job._id) &&
          (job.occurence === 'daily' || !job.lastRun) &&
          job.status?.type !== 'ERROR' &&
          differenceInHours(new Date(), new Date(job.lastRun)) >=
            DAILY_SYNC_INTERVAL_HOURS
        );
      });
      setJobQueue([...jobQueue, ...notChecked.map((job) => job._id)]);
      setIsDisconnectedWarning(false);
    }
    if (
      !Dropbox.isConnected &&
      !Dropbox.isConnecting &&
      !isLoadingJobs &&
      !!rawJobs.filter((job) => job.userId === User.djangoProfile.id)?.length
    ) {
      setIsDisconnectedWarning(true);
    }
  }, [
    Dropbox.isConnected,
    Dropbox.isConnecting,
    isLoadingJobs,
    jobs,
    rawJobs,
    isDisconnectedWarning,
  ]);

  async function queueJob(job, force) {
    if (!jobQueue.includes(job._id)) {
      setJobQueue([...jobQueue, job._id]);
    }
    if (force && !forceJob.includes(job._id)) {
      setForceJob([...forceJob, job._id]);
    }
  }

  async function runJobQueue() {
    const firstJob = jobQueue?.[0];
    if (isRunningJobs) {
      return false;
    }
    if (!firstJob) {
      setIsRunningJobs(false);
      return false;
    }
    const activeJob = jobs.find((job) => job._id === firstJob);
    if (!activeJob) {
      setIsRunningJobs(false);
      return false;
    }
    if (activeJob.status?.isRunning) {
      setJobQueue(jobQueue.filter((j, key) => !!key));
      return false;
    }
    setJobQueue(jobQueue.filter((j, key) => !!key));
    setIsRunningJobs(true);
    await runJob(activeJob);
    setIsRunningJobs(false);
  }

  async function getJobs() {
    setIsLoadingJobs(true);
    const data = await User.request.files.export.getJobs(INTEGRATION);
    // data.jobs = data.jobs.filter((job) => job.destination.siteType === INTEGRATION);
    setRawJobs(data.jobs);
    const jobs = await asyncPool(10, data.jobs, async (job) => {
      const Library =
        job.library === 'media'
          ? Media
          : job.library === 'documents'
            ? Documents
            : null;
      let site;
      let drive;
      let folder;
      folder = await Dropbox.getFolderItem(job.destination.folderId);
      if (job.destination.folderId) {
        folder = await Dropbox.getFolderItem(job.destination.folderId);
        if (folder?.error) {
          job.error = {
            code: 'NO_ACCESS',
          };
          if (!job.status) job.status = {};
          job.status.type = 'ERROR';
        }
      }
      return {
        ...job,
        metadata: {
          site,
          drive,
          folder,
          collections: Library?.data?.collections?.filter((col) =>
            job.collections.includes(col._id),
          ),
        },
      };
    });
    setJobs(jobs);
    const notChecked =
      jobs?.filter((job) => {
        return (
          (!job.status || (!job.status?.isRunning && !job.status?.isChecked)) &&
          !jobQueue.includes(job._id) &&
          (job.occurence === 'daily' || !job.lastRun) &&
          job.status?.type !== 'ERROR' &&
          differenceInHours(new Date(), new Date(job.lastRun)) >=
            DAILY_SYNC_INTERVAL_HOURS
        );
      }) || [];
    setJobQueue([...jobQueue, ...notChecked.map((job) => job._id)]);
    setIsLoadingJobs(false);
  }

  async function runJob(job) {
    function updateJobStatus(status) {
      if (
        window.location.href.includes('localhost') ||
        window.location.href.includes('azurestaticapps') ||
        window.location.href.includes('staging')
      ) {
        console.info({ dropboxExportStatus: status });
      }
      setJobs({ type: 'UPDATE_STATUS', jobId: job._id, status });
    }
    try {
      const Library =
        job.library === 'media'
          ? Media
          : job.library === 'documents'
            ? Documents
            : null;

      if (
        job.userId !== User.djangoProfile?.id &&
        !forceJob.includes(job._id)
      ) {
        updateJobStatus({
          type: 'ERROR',
          code: 'NOT_CREATOR',
          isLoading: false,
          isRunning: false,
          isChecked: true,
          current: 0,
          total: 0,
        });
        return false;
      }

      if (!Library) {
        updateJobStatus({
          type: 'ERROR',
          code: 'NO_LIBRARY_SELECTED',
          files: [],
        });
        return false;
      }

      if (job.error?.code) {
        return updateJobStatus({
          code: job.error?.code,
          isError: true,
          isRunning: false,
        });
      }

      updateJobStatus({ code: 'GETTING_PICKIT_FILES', isRunning: true });
      const map = [];
      const folderMap = job.maps?.collections || [];
      let filesMap = job.maps?.files || [];
      const allFiles = [];
      const allItems = [];
      let baseFolder;

      if (job.preferences.createFolders === false) {
        try {
          baseFolder = await Dropbox.getFolderItem(job.destination.folderId);
        } catch (e) {
          console.error('Failed to get base folder', e);
        }
      }
      const useBaseFolder =
        job.preferences.createFolders === false && !!baseFolder;
      await asyncPool(2, job.collections, async (collection) => {
        const metadata = await Library.getCollection(collection);
        let collectionFiles = await Library.getAllFilesInCollection(collection);
        collectionFiles = collectionFiles.filter(
          (file) => !!file.file?.url && !file?.file?.external_file,
        );
        const mapItem = job.maps?.collections?.find(
          (map) => map.collectionId === collection,
        );
        let item;
        allFiles.push(...collectionFiles);
        if (mapItem && !useBaseFolder) {
          item = await Dropbox.getFolderItem(mapItem.itemId);
          if (item.error) {
            item = null;
            folderMap.splice(
              folderMap.findIndex((it) => it.collectionId === collection),
              1,
            );
          }
        }
        map.push({
          collection: metadata,
          files: collectionFiles,
          item: useBaseFolder ? baseFolder : item,
        });
      });

      updateJobStatus({ code: 'CREATING_FOLDERS' });
      await asyncPool(1, map, async (mapItem) => {
        let { item } = mapItem;
        const name = mapItem.collection.name
          .trim()
          .replace(/^[._]|[!#%&*{}:<>?/|"~]|\.{2}|\.$|(?:\.files)/g, '_');
        if (!item) {
          const res = await Dropbox.createFolder(
            job?.metadata?.folder?.path_lower || job.destination.folderId,
            name,
          );
          item = res.metadata;
          if (res.error) {
            item = await Dropbox.getFolderItem(
              `${job.destination.folderId}/${name}`
                .replace(
                  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                  '',
                )
                .trim(),
            );
          }
          folderMap.push({
            itemId: item.id,
            collectionId: mapItem.collection._id,
          });
        }
        if (item && item.name !== name && !useBaseFolder) {
          await Dropbox.renameFolder(job.destination.folderId, name, item.name);
        }
        // This might not work. Replace with findIndex if so.
        map[map.indexOf(mapItem)] = {
          ...mapItem,
          item,
        };
      });

      updateJobStatus({ code: 'GETTING_FILES_IN_FOLDERS' });
      await asyncPool(2, map, async (mapItem) => {
        const items = await Dropbox.getAllFilesFromFolder(
          mapItem.item.id,
          true,
        );
        allItems.push(...(items || []));
        // Removing files which has been deleted in Sharepoint, hence the map is no longer valid.

        map[map.indexOf(mapItem)] = {
          ...mapItem,
          items,
        };
      });

      filesMap = filesMap.filter((mp) =>
        allItems.find((it) => it.id === mp.itemId),
      );

      updateJobStatus({ code: 'CHECKING_FILE_ALREADY_EXISTS' });
      await asyncPool(2, map, async (mapItem) => {
        const files = mapItem.files.map((file) => {
          return {
            ...file,
            alreadyUploaded: !!filesMap.find(
              (item) => item.fileId === file._id,
            ),
          };
        });
        map[map.indexOf(mapItem)] = {
          ...mapItem,
          files,
        };
      });

      const filesDeleted = filesMap.filter((fileMap) => {
        let isDeleted = true;
        map.find((mapItem) => {
          if (mapItem.files.find((file) => file._id === fileMap.fileId)) {
            isDeleted = false;
          }
        });
        return isDeleted;
      });
      if (filesDeleted?.length) {
        const current = 0;
        updateJobStatus({
          code: 'DELETING_REMOVED_FILES',
          current,
          total: filesDeleted?.length,
        });
        await asyncPool(2, filesDeleted, async (item) => {
          await Dropbox.deleteItem(item.itemId);
          filesMap = filesMap.filter((it) => it.itemId !== item.itemId);
          updateJobStatus({ current, total: filesDeleted?.length });
        });
      }

      let sasToken = await Library.getToken(Library.sasToken);

      async function uploadFile(file, replace) {
        sasToken = await Library.getToken(sasToken);
        let { file: fileToUpload } = file;
        let token = sasToken?.token?.main;
        let fileName = file?.name || file.file.name;
        const ext = file.file.ext.toLowerCase();
        const fileSize =
          job.preferences.imageSizeSpecific?.[file._id] ||
          job.preferences.imageSize?.[ext] ||
          'source';
        if (!job.preferences.imageSize?.[ext]) {
          return null;
        }
        if (
          fileSize === 'largest-preview' &&
          fileToUpload.contentType !== 'images/gif' &&
          !fileToUpload.contentType.includes('video') &&
          !fileToUpload.contentType.includes('audio') &&
          file.file.previews?.[0]
        ) {
          fileToUpload = file.file.previews?.[0];
          token = sasToken?.token?.thumbnails;
        }
        if (fileSize === 'ignore') {
          return null;
        }
        if (!fileToUpload) {
          return null;
        }
        if (fileToUpload.ext !== file.file.ext) {
          fileName = `${fileName.split('.')[0]}.jpg`;
        }
        current += 1;
        const downloadedFile = await fetch(
          `${fileToUpload.url?.split('?')?.[0]}?${token}`,
        );
        const data = await downloadedFile.blob();
        const fileReader = new File([data], fileName);
        if (!replace) {
          const uploadedFile = await Dropbox.uploadFile(
            file.destinationItem.id,
            fileReader,
            fileName,
          );
          if (uploadedFile.error) {
            if (uploadedFile.error_summary.includes('insufficient_space')) {
              throw new Error('NO_SPACE');
            } else {
              console.error(file);
              throw new Error(uploadedFile);
            }
          }
          filesMap.push({
            itemId: uploadedFile.id,
            fileId: file._id,
          });
        } else {
          await Dropbox.uploadFile(
            file.destinationItem.path_lower,
            fileReader,
            file.file.name,
            true,
          );
        }
      }

      const filesUpdated = filesMap
        .filter((fileMap) => {
          const file = allFiles.find((file) => file._id === fileMap.fileId);
          const item = allItems.find((item) => item.id === fileMap.itemId);
          if (!file || !item) {
            return false;
          }
          const itemExportedDate = new Date(item.server_modified);
          const fileUploadedDate = new Date(file.file.uploaded_at);
          return isAfter(fileUploadedDate, itemExportedDate);
        })
        .map((fileMap) => ({
          ...allFiles.find((file) => file._id === fileMap.fileId),
          destinationItem: allItems.find((item) => item.id === fileMap.itemId),
        }));
      let current = 0;
      updateJobStatus({
        code: 'UPLOADING_FILE_CHANGES',
        current,
        total: filesUpdated?.length,
      });
      await asyncPool(2, filesUpdated, async (file) => {
        await uploadFile(file, true);
        updateJobStatus({ current, total: filesNotUploaded?.length });
      });

      const filesNotUploaded = map.reduce((files, mapItem) => {
        return [
          ...files,
          ...mapItem.files
            .filter((file) => !file.alreadyUploaded)
            .map((file) => ({
              ...file,
              destinationItem: mapItem.item,
            })),
        ];
      }, []);
      let uploadCurrent = 0;
      updateJobStatus({
        code: 'UPLOADING_FILES',
        current: uploadCurrent,
        total: filesNotUploaded?.length,
      });
      await asyncPool(1, filesNotUploaded, async (file) => {
        await uploadFile(file);
        uploadCurrent += 1;
        updateJobStatus({
          code: 'UPLOADING_FILES',
          current: uploadCurrent,
          total: filesNotUploaded?.length,
        });
      });

      if (filesMap?.length) {
        await User.request.files.export.saveJob(INTEGRATION, {
          _id: job._id,
          lastRun: new Date(),
          maps: {
            collections: [...folderMap],
            files: [...filesMap],
          },
        });
      }

      updateJobStatus({
        code: 'JOB_CHECKED',
        isLoading: false,
        isRunning: false,
        isChecked: true,
        current: 0,
        total: 0,
      });
      /**
       * Check if Job collection has been deleted
       */
      const updatedJob = await User.request.files.export.saveJob(INTEGRATION, {
        _id: job._id,
        lastRun: new Date(),
      });
      setJobs(
        jobs.map((job) =>
          job._id === updatedJob._id
            ? {
                ...job,
                ...updatedJob,
                status: {
                  code: 'JOB_CHECKED',
                  isLoading: false,
                  isRunning: false,
                  isChecked: true,
                  current: 0,
                  total: 0,
                },
              }
            : job,
        ),
      );
    } catch (e) {
      console.error(e);
      updateJobStatus({
        type: 'ERROR',
        code: 'JOB_FAILED',
        error: e,
        isRunning: false,
        isLoading: false,
        isChecked: true,
        current: 0,
        total: 0,
      });
      return false;
    }
  }

  const translations = {
    GETTING_PICKIT_FILES: 'Fetching all Pickit files in collections',
    CREATING_FOLDERS: 'Creating folders at destination',
    GETTING_FILES_IN_FOLDERS: 'Getting files in created folders at destination',
    CHECKING_FILE_ALREADY_EXISTS: 'Checking which files already is uploaded',
    DELETING_REMOVED_FILES: 'Deleting removed Pickit files',
    UPLOADING_FILE_CHANGES: 'Uploading file changes from Pickit files',
    UPLOADING_FILES: 'Uploading files',
    JOB_CHECKED: 'Export completed successfully',
    NO_PERMISSION:
      'The destination you are trying it export to is not accessible by your account.',
    JOB_FAILED: 'Something went wrong. Please try again or contact support',
    QUEUED: 'Waiting for other export to finish...',
    NO_SPACE: 'Your Dropbox account is out of free space.',
    NO_ACCESS: "You don't have access to this resource at Dropbox",
    NOT_CREATOR: (
      <>
        You are not the creator of this export.
        <br />
        You can force it to sync at your own risk.
      </>
    ),
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }

  return {
    ...Dropbox,
    translateCode,

    saveJob: async (updates, clearStatus, metadata) => {
      try {
        const newJob = await User.request.files.export.saveJob(
          INTEGRATION,
          updates,
        );
        if (!updates._id) {
          setJobs([
            ...jobs,
            {
              ...newJob,
              metadata,
            },
          ]);
          queueJob({
            ...newJob,
            metadata: metadata || {},
          });
          return newJob;
        }
        setJobs(
          jobs.map((job) =>
            job._id === newJob._id
              ? {
                  ...job,
                  ...newJob,
                  metadata,
                  status: clearStatus ? null : job.status,
                }
              : job,
          ),
        );
        return newJob;
      } catch (e) {
        return e;
      }
    },

    deleteJob: async (jobId, prefs) => {
      await User.request.files.export.deleteJob(INTEGRATION, jobId, prefs);
      setJobs(jobs.filter((job) => job._id !== jobId));
    },

    isDisconnectedWarning,

    jobs,
    queueJob,
    jobQueue,

    isLoadingJobs,

    getJobs,
    INTEGRATION_NAME,
    INTEGRATION,
  };
}
