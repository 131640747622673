import {
  Box,
  Divider,
  Indicator,
  Paper,
  PaperProps,
  ScrollArea,
  Stack,
  Transition,
} from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiTooltip from '@pixi/elements/Tooltip';
import { useEffect, useState } from 'react';
import { Title } from '../AssetPanel/elements';
import AssetGridFilters from './components/AssetGridFilters';
import AssetGridFilterShortcuts from './components/AssetGridFilterShortcuts';
import { AssetGridLeftPanelProps } from './Helpers';
import { useAssetGridContext } from './AssetGridContext';
import useFullViewportHeight from '@pixi/hooks/useFullViewportHeight';
import { useUserContext } from 'hooks';
import { useColorScheme } from '@pixi/AppController';
import AssetGridSimilarity from './components/AssetGridSimilarity';
import useUserStatus from 'hooks/useUserStatus';

export default function AssetGridLeftPanel({
  forceLeftPanelSection,
  assetGridFiltersProps,
  ...rest
}: AssetGridLeftPanelProps) {
  const User = useUserContext();
  const userStatus = useUserStatus();
  const colorScheme = useColorScheme();
  const AssetGrid = useAssetGridContext();
  const [sectionOpen, setSectionOpen] = useState<string | null>(
    forceLeftPanelSection || null,
  );
  const { ref, height } = useFullViewportHeight();

  const currentLibrary = User.data?.selectedCommunity?.libraries?.find(
    (l: Pickit.CommunityLibraryInterface) =>
      AssetGrid.libraries?.includes(l.nameId),
  );
  const selectedCommunityLibrary =
    User.data?.selectedCommunityLibraries?.[AssetGrid.context.type];

  useEffect(() => {
    if (!AssetGrid.activeShortcuts?.length && sectionOpen === 'trash') {
      setSectionOpen(null);
    }
  }, [AssetGrid.activeShortcuts]);

  return (
    <>
      <Paper
        w="100%"
        maw={sectionOpen ? 300 : 64}
        mih={1}
        pos="sticky"
        top={0}
        ref={ref}
        bg={colorScheme === 'dark' ? 'transparent' : 'rgba(255, 255, 255, 0.5)'}
        style={{
          height,
          flexShrink: 0,
          transition: 'max-width .15s',
          overflow: 'hidden',
        }}
        {...rest}
      >
        {AssetGrid.leftPanelInjection}
        <ScrollArea w="100%" maw={300} miw={1} h="100%" scrollbars="y">
          <Box
            w="100%"
            h="100%"
            pos="relative"
            style={{ zIndex: 0 }}
            maw={sectionOpen ? 300 : 64}
          >
            {!sectionOpen && (
              <Stack w="100%" p="xs" pt="lg" gap="xs">
                <PixiTooltip label="Filters" position="right">
                  <PixiButton
                    px="xs"
                    size="md"
                    color={
                      Object.keys(AssetGrid?.activeFilters)?.length &&
                      !AssetGrid?.activeShortcuts?.length
                        ? 'primary'
                        : 'dark'
                    }
                    variant="light"
                    onClick={() => {
                      setSectionOpen('filters');
                    }}
                  >
                    <PixiIcon name="filter" />
                  </PixiButton>
                </PixiTooltip>
                {userStatus?.product?.isBusinessAdmin &&
                  !AssetGrid.readOnly &&
                  userStatus.activeFeatures?.attributes &&
                  !!currentLibrary?.preferences?.properties?.length && (
                    <PixiTooltip label="Properties" position="right">
                      <PixiButton
                        px="xs"
                        size="md"
                        color={
                          Object.keys(AssetGrid?.activeFilters)?.length &&
                          !AssetGrid?.activeShortcuts?.length &&
                          Object.keys(AssetGrid?.activeFilters)?.find((s) =>
                            s.startsWith('properties'),
                          )
                            ? 'primary'
                            : 'dark'
                        }
                        variant="light"
                        onClick={() => {
                          setSectionOpen('properties');
                        }}
                      >
                        <PixiIcon name="filter-list" />
                      </PixiButton>
                    </PixiTooltip>
                  )}
                {userStatus?.product?.isBusinessAdmin &&
                  !AssetGrid.readOnly && (
                    <PixiTooltip label="Shortcuts" position="right">
                      <PixiButton
                        px="xs"
                        size="md"
                        color={
                          AssetGrid?.activeShortcuts?.length &&
                          !!AssetGrid.shortcuts.filter(
                            (s) =>
                              !s.hidden &&
                              !!s?.data?.find((d) =>
                                AssetGrid.activeShortcutIds?.includes(d.id),
                              ),
                          )?.length
                            ? 'primary'
                            : 'dark'
                        }
                        variant="light"
                        onClick={() => {
                          setSectionOpen('filter-shortcuts');
                        }}
                      >
                        <PixiIcon name="star" />
                      </PixiButton>
                    </PixiTooltip>
                  )}

                {!AssetGrid.readOnly &&
                  userStatus?.product?.isBusinessAdmin && (
                    <PixiTooltip label="Trash" position="right">
                      <Indicator
                        label={selectedCommunityLibrary?.statuses?.trashCount}
                        size="xs"
                        offset={6}
                        color="red"
                        disabled={
                          !selectedCommunityLibrary?.statuses?.trashCount
                        }
                      >
                        <PixiButton
                          px="xs"
                          size="md"
                          color={
                            AssetGrid.activeShortcutIds?.includes(
                              'trash.active',
                            )
                              ? 'primary'
                              : 'dark'
                          }
                          variant="light"
                          onClick={async () => {
                            setSectionOpen('trash');
                            AssetGrid.setActiveShortcuts([
                              { id: 'trash.active' },
                            ]);
                          }}
                        >
                          <PixiIcon name="trash-can" />
                        </PixiButton>
                      </Indicator>
                    </PixiTooltip>
                  )}

                {User.data?.selectedCommunity?.settings?.preferences
                  ?.experimentalFeatures?.imageVector ? (
                  <PixiTooltip label="Image similarity" position="right">
                    <PixiButton
                      px="xs"
                      size="md"
                      variant="light"
                      color="dark"
                      onClick={async () => {
                        setSectionOpen('similarity');
                      }}
                    >
                      <PixiIcon name="microchip-ai" />
                    </PixiButton>
                  </PixiTooltip>
                ) : (
                  <></>
                )}
              </Stack>
            )}
            <Box
              w="100%"
              style={{
                opacity: sectionOpen ? 1 : 0,
                transition: 'opacity .15s',
                transitionDelay: sectionOpen ? '0.08s' : '0s',
              }}
            >
              {sectionOpen === 'filters' ? (
                <>
                  {!forceLeftPanelSection && (
                    <>
                      <Title
                        title="Filters"
                        icon={<PixiIcon name="filter" />}
                        onClose={() => {
                          setSectionOpen(null);
                        }}
                      />
                      <Divider />
                    </>
                  )}
                  <AssetGridFilters
                    onClose={() => {
                      setSectionOpen(null);
                    }}
                    noBackground={rest?.bg === 'transparent'}
                    {...assetGridFiltersProps}
                  />
                </>
              ) : null}
              {sectionOpen === 'trash' ? (
                <>
                  <Title
                    title="Trash"
                    icon={<PixiIcon name="trash-can" />}
                    onClose={() => {
                      setSectionOpen(null);
                    }}
                  />
                  <Divider />
                  <AssetGridFilterShortcuts
                    onClose={() => {
                      setSectionOpen(null);
                    }}
                    specificShortcuts={['trash.user']}
                    forceShortcutGroup="trash"
                  />
                </>
              ) : null}
              {sectionOpen === 'filter-shortcuts' ? (
                <>
                  <Title
                    title="Shortcuts"
                    icon={<PixiIcon name="star" />}
                    onClose={() => {
                      setSectionOpen(null);
                    }}
                  />
                  <Divider />
                  <AssetGridFilterShortcuts
                    onClose={() => {
                      setSectionOpen(null);
                    }}
                  />
                </>
              ) : null}
              {sectionOpen === 'properties' ? (
                <>
                  <Title
                    title="Properties"
                    icon={<PixiIcon name="filter-list" />}
                    onClose={() => {
                      setSectionOpen(null);
                    }}
                  />
                  <Divider />
                  <AssetGridFilters
                    onClose={() => {
                      setSectionOpen(null);
                    }}
                    noBackground={rest?.bg === 'transparent'}
                    specificFilters={Object.keys(
                      AssetGrid.availableFilters,
                    ).filter((v) => v.startsWith('properties.'))}
                    {...assetGridFiltersProps}
                  />
                </>
              ) : null}
              {sectionOpen === 'similarity' ? (
                <>
                  <Title
                    title="Image Similarity search"
                    icon={<PixiIcon name="microchip-ai" />}
                    onClose={() => {
                      setSectionOpen(null);
                    }}
                  />
                  <Divider />
                  <AssetGridSimilarity />
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </ScrollArea>
      </Paper>
    </>
  );
}
