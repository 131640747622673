import {
  Box,
  Group,
  Modal,
  ModalProps,
  Paper,
  PaperProps,
  ScrollArea,
  ScrollAreaAutosize,
  Stack,
  StackProps,
} from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';
import {
  createContext,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
  useContext,
} from 'react';
import { useColorScheme } from '@pixi/AppController';
import { mergeDeep } from 'immutable';
import PixiButton from '../Button';
import PixiIcon, { PixiIconName } from '../Icon';

const PixiPopupContext = createContext<{
  onClose?: () => void;
  isOpen?: boolean;
}>({});

const usePixiPopupContext = () => {
  return useContext(PixiPopupContext);
};

export type PixiPopupProps<C = 'div'> = PolymorphicComponentProps<
  C,
  ModalProps
> & {
  actions?: ReactNode;
  fullHeight?: boolean;
  noScroll?: boolean;
  isPanelPopup?: boolean;
  bodyProps?: Omit<StackProps, 'children'>;
};

const _PixiPopup = (
  props: PixiPopupProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { children, actions, fullHeight, noScroll, isPanelPopup, ...rest } =
    props;
  const colorScheme = useColorScheme();

  return (
    <Modal
      ref={ref}
      size={isPanelPopup ? 'xl' : undefined}
      {...rest}
      withCloseButton={!isPanelPopup}
      styles={mergeDeep(rest.styles || {}, {
        root: {
          ...(fullHeight ? { height: '100%' } : {}),
        },
        title: {
          width: '100%',
        },
        inner: {
          ...(fullHeight
            ? {
                height: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }
            : {}),
          ...(rest?.styles?.inner || {}),
        },
        header: {
          background:
            colorScheme === 'dark'
              ? 'var(--mantine-color-dark-8)'
              : 'var(--mantine-color-gray-1)',
          padding: 'var(--mantine-spacing-xl)',
        },
        content: {
          ...(typeof rest?.size === 'number' || rest?.size?.includes('%')
            ? { width: '100%', maxWidth: rest.size }
            : {}),
          ...(fullHeight
            ? {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'stretch',
                overflow: 'hidden',
              }
            : {}),
          ...(isPanelPopup
            ? {
                width: '100%',
                height: 'auto',
                background: 'transparent',
                boxShadow: '0 0 0',
                pointerEvents: 'none',
              }
            : {}),
          ...(rest?.styles?.content || {}),
        },
        body: {
          ...(fullHeight
            ? {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0,
              }
            : {}),
          padding: 0,
          ...(isPanelPopup
            ? {
                width: '100%',
                height: 'auto',
              }
            : {}),
          ...(rest?.styles?.body || {}),
        },
      })}
      closeOnClickOutside
    >
      <PixiPopupContext.Provider
        value={{
          onClose: () => {
            rest?.onClose?.();
          },
          isOpen: rest?.opened,
        }}
      >
        {isPanelPopup ? (
          <>
            <Group
              w="100%"
              mah="100%"
              align="flex-start"
              justify="center"
              gap="5"
              wrap="nowrap"
            >
              {children}
            </Group>
          </>
        ) : noScroll ? (
          <Stack h="100%" mih={1} pos="relative">
            {children}
          </Stack>
        ) : (
          <ScrollArea h={fullHeight ? '100%' : 'auto'}>
            <Box h="100%" mih={1} pos="relative">
              {children}
            </Box>
          </ScrollArea>
        )}
        {actions ? (
          <Paper
            w="100%"
            p="lg"
            bg={colorScheme === 'dark' ? 'dark.8' : 'gray.0'}
            style={{ flexShrink: 0 }}
          >
            <Group w="100%" gap={0} wrap="nowrap">
              {actions}
            </Group>
          </Paper>
        ) : (
          <></>
        )}
      </PixiPopupContext.Provider>
    </Modal>
  );
};

const Panel = ({
  children,
  withCloseButton,
  title,
  icon,
  ...rest
}: {
  children: ReactNode;
  title?: ReactNode;
  icon?: PixiIconName;
  withCloseButton?: boolean;
} & PaperProps) => {
  const { onClose, isOpen } = usePixiPopupContext();
  return (
    <Paper
      {...rest}
      mah="100%"
      style={{
        overflow: 'auto',
        pointerEvents: 'auto',
        ...(rest.style || {}),
      }}
    >
      {withCloseButton || title ? (
        <Group w="100%" h={64} px="md" bg="gray.1" justify="space-between">
          {title ? (
            <>
              <Group>
                {icon && (
                  <>
                    <PixiIcon name={icon} />
                  </>
                )}
                {title}
              </Group>
            </>
          ) : (
            <></>
          )}
          {withCloseButton && (
            <PixiButton
              onClick={() => {
                onClose?.();
              }}
              px="sm"
              variant="subtle"
              color="dark"
              ml="auto"
            >
              <PixiIcon name="xmark" />
            </PixiButton>
          )}
        </Group>
      ) : (
        <></>
      )}
      <ScrollArea h="100%">{children}</ScrollArea>
    </Paper>
  );
};

interface PixiPopupType
  extends ForwardRefExoticComponent<
    PixiPopupProps & RefAttributes<HTMLDivElement>
  > {
  Panel: typeof Panel;
}

const PixiPopup = forwardRef(_PixiPopup) as PixiPopupType;
PixiPopup.Panel = Panel;
export default PixiPopup;
