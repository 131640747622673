import { Group, TextInput } from '@mantine/core';
import { DateInput, DateValue } from '@mantine/dates';
import { createAppToast } from '@pixi/AppController';
import PixiButton from '@pixi/elements/Button';
import PixiForm, { PixiFormProps } from '@pixi/elements/Form';
import Field from '@pixi/elements/Field';
import PixiIcon from '@pixi/elements/Icon';
import { startOfTomorrow } from 'date-fns';
import { useUserContext } from 'hooks';
import useContextFromFile from 'hooks/useContextFromFile';
import { ReactElement } from 'react';
import slugify from 'slugify';
import { copyTextToClipboard } from 'utils';

export default function TransferForm({
  file,
  transfer,
  setIsFreezeDropdown,
  target,
  ...rest
}: {
  file: Pickit.FileInterface;
  transfer?: Pickit.FileInterfaceTransfer;
  setIsFreezeDropdown?: (isFreeze: boolean) => void;
  target: ReactElement;
} & Partial<
  PixiFormProps<{
    name: string;
    expiresAt?: DateValue;
  }>
>) {
  const User = useUserContext();
  const context = useContextFromFile(file);

  return (
    <PixiForm<{
      name: string;
      title?: string;
      expiresAt?: DateValue;
    }>
      type="dropdown"
      width={400}
      customActions={({ data, setData, submit, setIsOpen }) => {
        return (
          <Group gap="5" wrap="nowrap">
            <PixiButton
              disabled={!data?.name}
              onClick={() => {
                submit();
                setIsOpen(false);
              }}
              variant="light"
              fullWidth={!data?.name}
              style={{
                flexWrap: 'nowrap',
                whiteSpace: 'nowrap',
                flexShrink: 0,
              }}
            >
              Create link
            </PixiButton>
            {!!data?.name && (
              <PixiButton
                fullWidth
                disabled={!data?.name}
                onClick={async () => {
                  const newFile = (await submit()) as Pickit.FileInterface;
                  const lastTransfer = newFile?.transfers?.pop();
                  if (!lastTransfer) {
                    return;
                  }
                  const url = `${window.location.origin}/#/transfer/${lastTransfer?._id}`;
                  navigator.clipboard.writeText(url);
                  createAppToast({
                    message: 'Link copied to clipboard',
                    id: lastTransfer?._id as string,
                  });
                  setIsOpen(false);
                }}
                leftSection={<PixiIcon name="copy" />}
              >
                Create and copy link
              </PixiButton>
            )}
          </Group>
        );
      }}
      form={{
        name: {
          key: 'name',
          value: transfer?.name || '',
          render: ({ value, setValue }) => (
            <TextInput
              label="Title"
              description="Will only be displayed for admins"
              value={value}
              onChange={(event) => {
                setValue(event.currentTarget.value);
              }}
              placeholder="E.g. Sent to John Doe"
            />
          ),
        },
        title: {
          key: 'title',
          value: transfer?.title,
          render: ({ value, setValue }) => (
            <TextInput
              label="Message to reciever"
              value={value}
              onChange={(event) => {
                setValue(event.currentTarget.value);
              }}
              placeholder="E.g. PowerPoint Template 2024"
            />
          ),
        },
        expiresAt: {
          key: 'expiresAt',
          value: transfer?.expiresAt ? new Date(transfer?.expiresAt) : null,
          render: ({ value, setValue }) => (
            <DateInput
              name="No expire"
              label="Expire date"
              value={value}
              onChange={(date) => setValue(date)}
              placeholder="(No expiration)"
              minDate={startOfTomorrow()}
              popoverProps={{
                zIndex: 9999,
                classNames: {
                  dropdown: 'pixi-Menu-dropdown',
                },
              }}
            />
          ),
        },
      }}
      submitLabel="Create link"
      submitProps={(data) => ({
        disabled: !data.name,
      })}
      onSubmit={async ({ name, expiresAt, title }) => {
        if (transfer?._id) {
          return await context.savePartial(
            {
              _id: file._id,
              'transfers.$.name': name,
              'transfers.$.title': title,
              'transfers.$.expiresAt': expiresAt,
            },
            {
              'transfers._id': transfer._id,
            },
          );
        }
        return await context.savePartial({
          _id: file._id,
          $push: {
            transfers: {
              name,
              title,
              expiresAt,
              createdAt: new Date(),
              createdBy: User.djangoProfile?.id,
            },
          },
        });
      }}
      target={target}
      onOpen={() => {
        setIsFreezeDropdown?.(true);
      }}
      onClose={() => {
        setIsFreezeDropdown?.(false);
      }}
      title="Create shareable link"
      {...rest}
    />
  );
}
