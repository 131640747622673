import React, { MouseEvent } from 'react';
import {
  Box,
  BoxProps,
  MantineColor,
  MantineSize,
  useMantineTheme,
} from '@mantine/core';
import { IconName } from './IconName';
import './style.css';

export type PixiIconName = IconName;

export interface PixiIconProps extends Omit<BoxProps, 'name'> {
  name: IconName;
  size?: MantineSize | number;
  variant?: 'light' | 'regular' | 'filled' | 'duotone';
  fallback?: IconName;
  color?: MantineColor;
  onClick?: (event: MouseEvent) => void;
}

const PixiIcon = React.forwardRef<HTMLDivElement, PixiIconProps>(
  (props: PixiIconProps, ref) => {
    const theme = useMantineTheme();
    const { name, size, variant, color, style, onClick, ...rest } = props;

    const iconSize =
      size === 'xl'
        ? 16
        : size === 'lg'
          ? 15
          : size === 'sm'
            ? 11
            : size === 'xs'
              ? 9
              : size || 15;

    // Define the FontAwesome class based on the variant
    const variantClass =
      name === 'facebook' ||
      name === 'twitter' ||
      name === 'x-twitter' ||
      name === 'linkedin'
        ? 'fa-brands'
        : variant === 'light'
          ? 'fa-light'
          : variant === 'filled'
            ? 'fa-solid'
            : variant === 'duotone'
              ? 'fa-duotone'
              : 'fa-regular';

    return (
      <Box
        style={{
          minWidth: '1em',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: color
            ? theme.colors[color === 'primary' ? theme.primaryColor : color][6]
            : undefined,
          fontSize: iconSize,
          ...(style || {}),
        }}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        {/* Use FontAwesome classes */}
        <i className={`${variantClass} fa-${name}`} />
      </Box>
    );
  },
);

export default PixiIcon;
