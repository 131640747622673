import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiTooltip from '@pixi/elements/Tooltip';
import { ToastService } from 'elements/Toast';
import { useTextContext, useUserContext } from 'hooks';
import useNotifications from 'hooks/useNotifications';
import { useState } from 'react';
import { copyTextToClipboard, downloadUrl } from 'utils';
import PixiDropdown from '@pixi/elements/Dropdown';
import { Box, TextInput } from '@mantine/core';
import useUserStatus from 'hooks/useUserStatus';

export default function CollectionToolbar({
  collection,
  totalFiles,
  context,
  disableActions,
}) {
  const User = useUserContext();
  const Text = useTextContext();
  const userStatus = useUserStatus();
  const Notifications = useNotifications();
  const [updatedExternalUsers, setUpdatedExternalUsers] = useState('');

  const [isFollowingCollection, setIsFollowingCollection] = useState(
    !!User.cloudStorage.following?.collections?.find(
      (item) => item.id === collection?._id,
    ),
  );

  async function inviteExternalUsers() {
    await context.saveCollection({
      _id: collection._id,
      share_to_external_users: [
        ...collection.share_to_external_users,
        updatedExternalUsers,
      ],
    });
    setUpdatedExternalUsers('');
    ToastService.createToast({
      message: 'Users invited',
    });
  }

  const url = `https://share.pickit.com/${context.type}/${collection?._id}`;
  return (
    <>
      {/* {!!downloadBagFiles.length && (
        <PixiDropdown
          width={250}
          target={
            <PixiTooltip label="Download files">
              <PixiButton px="sm" variant="filled" color="primary">
                <PixiIcon name="bag-shopping" />
              </PixiButton>
            </PixiTooltip>
          }
        >
          <AppDownloadBag />
        </PixiDropdown>
      )} */}
      {collection?.share_to_anonymous && (
        <PixiTooltip delay={0.3} label="Share">
          <PixiDropdown
            width={300}
            target={
              <PixiButton
                px="sm"
                size="sm"
                h="auto"
                variant="glass"
                color="dark"
              >
                <PixiIcon name="share" />
              </PixiButton>
            }
          >
            <PixiDropdown.Item
              as="a"
              transparent
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              target="_BLANK"
              rel="noreferrer noopener"
              icon="facebook"
            >
              Facebook
            </PixiDropdown.Item>
            <PixiDropdown.Item
              as="a"
              transparent
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
              target="_BLANK"
              rel="noreferrer noopener"
              icon="linkedin"
            >
              Linkedin
            </PixiDropdown.Item>
            <PixiDropdown.Item
              as="a"
              transparent
              href={`http://twitter.com/share?text=${collection.name}&url=${url}`}
              target="_BLANK"
              rel="noreferrer noopener"
              icon="x-twitter"
            >
              X (Twitter)
            </PixiDropdown.Item>
            <PixiDropdown.Item
              as="a"
              transparent
              onClick={() => copyTextToClipboard(url)}
              rel="noreferrer noopener"
              icon="link-horizontal"
            >
              Link
            </PixiDropdown.Item>

            <PixiDropdown.Item
              as="a"
              transparent
              href={`mailto:?subject=${Text.get('share/access').replace(
                'XX',
                collection.name,
              )}&body=${Text.get('share/email_copy_collection')}: \n\r${url}`}
              icon="envelope"
            >
              {Text.get('share/email_link')}
            </PixiDropdown.Item>

            {User.authenticated &&
              !userStatus.product.isExternalUser &&
              User.spaces?.selected?.slug === collection?.communitySlug &&
              collection?.allow_share_to_external_users_from_end_user && (
                <PixiDropdown
                  trigger="hover"
                  position="right-start"
                  target={
                    <PixiDropdown.Item
                      icon="envelope"
                      rightSection={<PixiIcon name="chevron-right" />}
                    >
                      Share to external user
                    </PixiDropdown.Item>
                  }
                >
                  <PixiDropdown.Label>
                    {Text.get('ui/share_to_external_user/label')}
                  </PixiDropdown.Label>
                  <Box p="xs">
                    <TextInput
                      placeholder={Text.get(
                        'ui/share_to_external_user/invite_email',
                      )}
                      value={updatedExternalUsers}
                      onChange={(event) =>
                        setUpdatedExternalUsers(event.currentTarget.value)
                      }
                    />
                    <PixiButton
                      mt="xs"
                      fullWidth
                      onClick={async () => {
                        await inviteExternalUsers();
                      }}
                    >
                      Invite user
                    </PixiButton>
                  </Box>
                </PixiDropdown>
              )}
          </PixiDropdown>
        </PixiTooltip>
      )}
      {collection?.preferences?.enable_zip_download && (
        <PixiTooltip
          delay={0.3}
          label={Text.get('view/collection/download_as_zip')}
        >
          <PixiButton
            px="sm"
            size="sm"
            h="auto"
            variant="glass"
            color="dark"
            onClick={() => {
              downloadUrl(
                `https://collection-downloader.azurewebsites.net/download/collection?collection_id=${
                  collection._id
                }${
                  User.token?.jwt ? `&jwt=${User.token?.jwt}` : ''
                }&community_slug=${collection.communitySlug}&service=${
                  collection.libraryType
                }&context=${context}`,
              );
            }}
          >
            <PixiIcon name="cloud-arrow-down" />
          </PixiButton>
        </PixiTooltip>
      )}
      {!disableActions?.includes('favorite') && User.authenticated && (
        <PixiTooltip
          delay={0.3}
          label={
            isFollowingCollection
              ? Text.get('view/collection/unfollow')
              : Text.get('view/collection/follow')
          }
        >
          <PixiButton
            px="sm"
            size="sm"
            h="auto"
            variant="glass"
            color="dark"
            onClick={() => {
              setIsFollowingCollection(!isFollowingCollection);
              if (isFollowingCollection) {
                Notifications.unfollowCollection(collection);
                ToastService.createToast({
                  message: Text.get('view/collection/unfollowed'),
                });

                return;
              }
              Notifications.followCollection(collection, totalFiles);
              ToastService.createToast({
                message: Text.get('view/collection/following'),
              });
            }}
          >
            <PixiIcon
              variant={isFollowingCollection ? 'filled' : undefined}
              name="heart"
            />
          </PixiButton>
        </PixiTooltip>
      )}
    </>
  );
}
